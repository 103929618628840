import React, { useEffect, useRef } from "react";
import { useVideoStore } from "stores/videoStore";
import { useTitle } from "utils/functions"


const userVideos = [
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-18T10:01:53.827Z",
            "playlistHistories": [],
            "totalWatchTime": 0,
            "userProfileId": "9cd609a0-a3a3-419a-9fdd-2c04170ee6d1",
            "userVideoCreationDate": "2024-12-18T10:01:53.827Z",
            "videoHistories": []
        },
        "userVideoId": "d67a8afc-7593-42b5-8837-e01aaccde409"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-18T08:56:55.525Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 0,
                    "playlistId": "b50fd3af-a7cf-4d64-9974-8804dfce3ef0",
                    "seconds": 0,
                    "videoIds": [
                        "dbd5df9a-4398-4c1e-9458-ae6c5e87be1c",
                        "5f6320bb-75fb-4377-a0c1-0f7b0ade6324",
                        "ff385a2a-6049-4bf3-9030-e3a12c30b922",
                        "8b3dd8be-2b95-4eb9-8fdd-08372ccc4f9f",
                        "f72d2645-5afd-4088-8481-9f4d58302c41",
                        "3c5a505e-912d-4828-966f-25ad45e0bd64"
                    ],
                    "watching": true
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a1b84dbf-a4a9-44ef-9f10-9a558c578d73",
                    "seconds": 0,
                    "videoIds": [
                        "bc632f44-8023-41d2-b131-065cfe933538",
                        "62219c36-a6e4-4699-a0b1-639c4be434bd",
                        "27f64dff-ce50-44d7-a294-5085b492ff38",
                        "1dfdf7c6-f314-4930-b540-2230519eee3d",
                        "c7d16f43-118f-4f68-8911-ecedd608ea1b",
                        "4625f3d0-d6d6-4f72-a304-63e56be1a8e8",
                        "f02c2b10-1a29-4553-b122-832f5ab1c3c7"
                    ],
                    "watching": true
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                        "e86bdb28-d358-4a33-91ba-aa2044f40b73"
                    ],
                    "watching": true
                }
            ],
            "totalWatchTime": 309,
            "userProfileId": "06bc380f-e616-4ea8-8b86-a78ccbaf39ac",
            "userVideoCreationDate": "2024-12-16T15:18:03.309Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.087,
                    "seconds": 6,
                    "videoId": "3c5a505e-912d-4828-966f-25ad45e0bd64",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8b3dd8be-2b95-4eb9-8fdd-08372ccc4f9f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "ff385a2a-6049-4bf3-9030-e3a12c30b922",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "5f6320bb-75fb-4377-a0c1-0f7b0ade6324",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dbd5df9a-4398-4c1e-9458-ae6c5e87be1c",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f72d2645-5afd-4088-8481-9f4d58302c41",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "seconds": 0,
                    "videoId": "9d5f1a75-b4c0-4d8e-921e-6ff2ba5869db",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f02c2b10-1a29-4553-b122-832f5ab1c3c7",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "4625f3d0-d6d6-4f72-a304-63e56be1a8e8",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c7d16f43-118f-4f68-8911-ecedd608ea1b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "1dfdf7c6-f314-4930-b540-2230519eee3d",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "27f64dff-ce50-44d7-a294-5085b492ff38",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62219c36-a6e4-4699-a0b1-639c4be434bd",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bc632f44-8023-41d2-b131-065cfe933538",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                }
            ]
        },
        "userVideoId": "273e345e-1f27-4ffc-8007-0b51a426cf60"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-18T08:53:51.559Z",
            "playlistHistories": [],
            "totalWatchTime": 0,
            "userProfileId": "818b8a63-051c-4dd4-ac3b-6e887e3dac75",
            "userVideoCreationDate": "2024-12-18T08:53:51.559Z",
            "videoHistories": []
        },
        "userVideoId": "f0e77eaf-1413-449b-aa2c-67f49d4f1df2"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-18T08:52:58.389Z",
            "playlistHistories": [],
            "totalWatchTime": 0,
            "userProfileId": "a0b8f541-84c3-484a-9c97-2898df732c5e",
            "userVideoCreationDate": "2024-12-18T08:52:58.389Z",
            "videoHistories": []
        },
        "userVideoId": "4b431356-e782-47a1-907f-d9ddc33cbeda"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-18T07:10:13.550Z",
            "playlistHistories": [],
            "totalWatchTime": 0,
            "userProfileId": "22b188b7-7a5e-4e60-b330-6f915aeae34b",
            "userVideoCreationDate": "2024-12-18T07:10:13.550Z",
            "videoHistories": []
        },
        "userVideoId": "4890ec1c-ef91-4d9d-8aa7-630fe083ccde"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T22:18:33.827Z",
            "playlistHistories": [],
            "totalWatchTime": 0,
            "userProfileId": "76de2e08-1efa-42db-9555-4235917e6747",
            "userVideoCreationDate": "2024-12-17T22:18:33.827Z",
            "videoHistories": []
        },
        "userVideoId": "06bc01c5-5efe-4c45-8d6f-1f83dc05a0e7"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T18:21:49.391Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "b4ada816-ce82-44f8-8c1d-e58772d5e449",
                    "seconds": 0,
                    "videoIds": [
                        "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                        "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                        "62a0bd71-170c-4c78-9a11-066607379d7f",
                        "669d5450-eaf0-4470-b80d-152b8dd27695",
                        "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                        "f7b1a8d7-d6a3-4953-9e00-29ce0228183f"
                    ],
                    "watching": true
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [],
                    "watching": true
                }
            ],
            "totalWatchTime": 1360,
            "userProfileId": "97a9c204-644b-45ec-b9a3-1f0941b5abd7",
            "userVideoCreationDate": "2024-12-11T09:41:14.316Z",
            "videoHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 0.002,
                    "seconds": 0,
                    "videoId": "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "669d5450-eaf0-4470-b80d-152b8dd27695",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62a0bd71-170c-4c78-9a11-066607379d7f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.004,
                    "seconds": 2,
                    "videoId": "4fbe05af-8322-4bef-bd2e-94eb25615dd5",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "f9781493-b09f-4d1b-9d26-6ad6490b055f"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T16:12:16.237Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a1b84dbf-a4a9-44ef-9f10-9a558c578d73",
                    "seconds": 0,
                    "videoIds": [
                        "bc632f44-8023-41d2-b131-065cfe933538",
                        "62219c36-a6e4-4699-a0b1-639c4be434bd",
                        "27f64dff-ce50-44d7-a294-5085b492ff38",
                        "1dfdf7c6-f314-4930-b540-2230519eee3d",
                        "c7d16f43-118f-4f68-8911-ecedd608ea1b"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "9ac8259b-cd09-483b-abba-98477ff4288d",
            "userVideoCreationDate": "2024-12-16T14:34:17.581Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.413,
                    "seconds": 110,
                    "videoId": "4625f3d0-d6d6-4f72-a304-63e56be1a8e8",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c7d16f43-118f-4f68-8911-ecedd608ea1b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "1dfdf7c6-f314-4930-b540-2230519eee3d",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "27f64dff-ce50-44d7-a294-5085b492ff38",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62219c36-a6e4-4699-a0b1-639c4be434bd",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bc632f44-8023-41d2-b131-065cfe933538",
                    "watching": false
                }
            ]
        },
        "userVideoId": "9e8b1b35-8c27-4c3b-a458-bb2a1d07af1b"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T15:56:53.585Z",
            "playlistHistories": [],
            "userProfileId": "3735502d-d5f9-4b1d-a8a2-0853805dc89c",
            "userVideoCreationDate": "2024-12-17T15:56:05.054Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "f9666d37-63df-45f0-8ee9-795bc1e4a912"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T15:54:54.550Z",
            "playlistHistories": [],
            "userProfileId": "d75e3039-c434-44fa-894a-c041cd907e8c",
            "userVideoCreationDate": "2024-12-17T15:54:54.550Z",
            "videoHistories": []
        },
        "userVideoId": "85580358-a235-4fed-a788-c52617f88a82"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T15:18:44.151Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 0,
                    "playlistId": "b4ada816-ce82-44f8-8c1d-e58772d5e449",
                    "seconds": 0,
                    "videoIds": [
                        "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                        "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                        "62a0bd71-170c-4c78-9a11-066607379d7f",
                        "669d5450-eaf0-4470-b80d-152b8dd27695",
                        "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                        "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                        "4fbe05af-8322-4bef-bd2e-94eb25615dd5",
                        "b5a9aef1-90ae-46a1-9dba-586bab9dd73e",
                        "4571fec5-8f84-479e-972f-a9e9e76a4496",
                        "a85bec92-0908-426e-b1f8-d5cc5fb31bee",
                        "8a5249b8-635a-4932-9c54-58561b932640"
                    ],
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                        "e86bdb28-d358-4a33-91ba-aa2044f40b73"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "afb16a1a-c2bf-4e5d-81b9-c86ad0fd83d2",
            "userVideoCreationDate": "2024-12-17T12:58:46.754Z",
            "videoHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8a5249b8-635a-4932-9c54-58561b932640",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a85bec92-0908-426e-b1f8-d5cc5fb31bee",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "4571fec5-8f84-479e-972f-a9e9e76a4496",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "b5a9aef1-90ae-46a1-9dba-586bab9dd73e",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "4fbe05af-8322-4bef-bd2e-94eb25615dd5",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "669d5450-eaf0-4470-b80d-152b8dd27695",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62a0bd71-170c-4c78-9a11-066607379d7f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "4c227d2e-b893-421c-bdd9-225dd1b7adf3"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T15:07:52.860Z",
            "playlistHistories": [],
            "userProfileId": "504922ea-177a-478f-a07a-0d2ef0587f44",
            "userVideoCreationDate": "2024-12-17T15:07:52.860Z",
            "videoHistories": []
        },
        "userVideoId": "e83bcc65-1ba7-42a2-a33d-1015d19b9c5a"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T14:42:48.506Z",
            "playlistHistories": [],
            "userProfileId": "5008e20a-59ff-4ae2-868a-2d9e542d6fbe",
            "userVideoCreationDate": "2024-12-17T14:42:48.506Z",
            "videoHistories": []
        },
        "userVideoId": "af266b17-9e4f-427b-a20c-1d3748e89a35"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T14:08:18.857Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "b4ada816-ce82-44f8-8c1d-e58772d5e449",
                    "seconds": 0,
                    "videoIds": [
                        "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                        "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                        "62a0bd71-170c-4c78-9a11-066607379d7f",
                        "669d5450-eaf0-4470-b80d-152b8dd27695",
                        "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                        "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                        "4fbe05af-8322-4bef-bd2e-94eb25615dd5",
                        "b5a9aef1-90ae-46a1-9dba-586bab9dd73e",
                        "4571fec5-8f84-479e-972f-a9e9e76a4496"
                    ],
                    "watching": true
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                        "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "920f6d4a-dcb3-48ec-ac97-2cb6ec387eca",
            "userVideoCreationDate": "2024-12-16T14:07:44.779Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.997,
                    "seconds": 297,
                    "videoId": "8a5249b8-635a-4932-9c54-58561b932640",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.968,
                    "seconds": 249,
                    "videoId": "a85bec92-0908-426e-b1f8-d5cc5fb31bee",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "4571fec5-8f84-479e-972f-a9e9e76a4496",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "b5a9aef1-90ae-46a1-9dba-586bab9dd73e",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "4fbe05af-8322-4bef-bd2e-94eb25615dd5",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "669d5450-eaf0-4470-b80d-152b8dd27695",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62a0bd71-170c-4c78-9a11-066607379d7f",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f9be2c14-3840-4b02-8f95-4fdcde28a418",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 0.195,
                    "seconds": 18,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.998,
                    "seconds": 358,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.994,
                    "seconds": 121,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.237,
                    "seconds": 16,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "086c2d24-6af2-44eb-9825-4f1a1dac0dac"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T13:48:38.471Z",
            "playlistHistories": [],
            "userProfileId": "a26ca69f-0565-4343-878b-54cc595b80ad",
            "userVideoCreationDate": "2024-12-17T13:48:38.471Z",
            "videoHistories": []
        },
        "userVideoId": "b1a79f96-3af6-4d1d-a95d-62b32d1c0416"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T13:47:42.259Z",
            "playlistHistories": [],
            "userProfileId": "e60c36f0-b81b-45d3-8005-6dde2525eda1",
            "userVideoCreationDate": "2024-12-17T13:47:42.259Z",
            "videoHistories": []
        },
        "userVideoId": "4bc45f31-9a7a-44b2-a8f8-ee4fd3e17ebd"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T13:44:36.628Z",
            "playlistHistories": [],
            "userProfileId": "6053e4a6-e898-4cb9-b4c9-6ffef2d9a77e",
            "userVideoCreationDate": "2024-12-17T13:44:36.628Z",
            "videoHistories": []
        },
        "userVideoId": "6a13fe43-90c6-4c0c-ba1d-82de906a7be7"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T13:25:27.498Z",
            "playlistHistories": [],
            "userProfileId": "ef7c0239-39a6-4bed-b4f5-a301e42d8fc6",
            "userVideoCreationDate": "2024-12-17T13:25:27.498Z",
            "videoHistories": []
        },
        "userVideoId": "bc6461a3-6cc1-406c-8e05-af8651cf00ff"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T13:19:48.740Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a1b84dbf-a4a9-44ef-9f10-9a558c578d73",
                    "seconds": 0,
                    "videoIds": [
                        "9d5f1a75-b4c0-4d8e-921e-6ff2ba5869db",
                        "bc632f44-8023-41d2-b131-065cfe933538",
                        "62219c36-a6e4-4699-a0b1-639c4be434bd",
                        "27f64dff-ce50-44d7-a294-5085b492ff38",
                        "1dfdf7c6-f314-4930-b540-2230519eee3d",
                        "c7d16f43-118f-4f68-8911-ecedd608ea1b"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "56ab9af6-5d63-41d8-b710-8224ff415b40",
            "userVideoCreationDate": "2024-12-17T06:44:42.034Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.885,
                    "seconds": 237,
                    "videoId": "4625f3d0-d6d6-4f72-a304-63e56be1a8e8",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c7d16f43-118f-4f68-8911-ecedd608ea1b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "1dfdf7c6-f314-4930-b540-2230519eee3d",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "27f64dff-ce50-44d7-a294-5085b492ff38",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "62219c36-a6e4-4699-a0b1-639c4be434bd",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bc632f44-8023-41d2-b131-065cfe933538",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "9d5f1a75-b4c0-4d8e-921e-6ff2ba5869db",
                    "watching": false
                }
            ]
        },
        "userVideoId": "ebaba634-df4f-4611-9293-8f2f1b3d6786"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T12:26:11.225Z",
            "playlistHistories": [],
            "userProfileId": "b30bd7a5-33a8-4675-a022-bfea9976fdf1",
            "userVideoCreationDate": "2024-12-17T12:26:11.225Z",
            "videoHistories": []
        },
        "userVideoId": "5b933b8c-ade1-4c8c-8522-7f51e0abe426"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:45:54.716Z",
            "playlistHistories": [],
            "userProfileId": "6841bd49-93a6-4e90-9b8c-b33ffcc02081",
            "userVideoCreationDate": "2024-12-17T11:45:54.716Z",
            "videoHistories": []
        },
        "userVideoId": "31ab4d32-e02d-4e01-90ad-2ea3d0cecc31"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:35:16.734Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "1cc7d619-08b5-43d2-801d-6b27afa1a9c3",
            "userVideoCreationDate": "2024-12-17T11:14:22.603Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.632,
                    "seconds": 187,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "060f39b3-3923-4477-b9ce-7a58c05262d3"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:25:24.859Z",
            "playlistHistories": [],
            "userProfileId": "e87838e1-2cd4-469c-8f13-d94c4a4868bb",
            "userVideoCreationDate": "2024-12-17T11:25:24.859Z",
            "videoHistories": []
        },
        "userVideoId": "24dd81df-99ed-4260-be5f-d701a9fbb4fa"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:23:07.853Z",
            "playlistHistories": [],
            "userProfileId": "52dd0818-bac0-4c9e-8ac6-2f65753b07ae",
            "userVideoCreationDate": "2024-12-17T11:23:07.853Z",
            "videoHistories": []
        },
        "userVideoId": "8eaf9b5b-663f-4382-9a8a-8ead40e0f811"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:20:30.738Z",
            "playlistHistories": [],
            "userProfileId": "c005c45e-5c2f-4275-b398-93de3f67a41d",
            "userVideoCreationDate": "2024-12-17T11:20:30.738Z",
            "videoHistories": []
        },
        "userVideoId": "20b10892-3c22-45c0-aa69-99b859bc5aa9"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:11:46.611Z",
            "playlistHistories": [],
            "userProfileId": "3b867d0a-feb8-4f5a-8354-f98de586e9f4",
            "userVideoCreationDate": "2024-12-17T11:11:46.611Z",
            "videoHistories": []
        },
        "userVideoId": "ead896b9-88e2-4a3a-8243-5df353dba9ad"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:10:50.078Z",
            "playlistHistories": [],
            "userProfileId": "879d1b16-8475-43cd-9cd7-c894442b5395",
            "userVideoCreationDate": "2024-12-17T11:10:50.078Z",
            "videoHistories": []
        },
        "userVideoId": "0ca4a79a-1d04-4c97-ad3e-07c0dfe30413"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:04:42.625Z",
            "playlistHistories": [],
            "userProfileId": "73d769b2-2bbb-46ad-92e3-df510c2e2c46",
            "userVideoCreationDate": "2024-12-17T11:04:42.625Z",
            "videoHistories": []
        },
        "userVideoId": "6d4e866f-8b27-44d1-87ee-6547abdf85d9"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T11:01:03.215Z",
            "playlistHistories": [],
            "userProfileId": "a1c963e2-761a-4e8f-b925-2bc5cbf3a323",
            "userVideoCreationDate": "2024-12-17T11:01:03.215Z",
            "videoHistories": []
        },
        "userVideoId": "9040a954-dd50-4d48-8309-7cffc3ca878d"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T10:37:47.577Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                        "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "ba0ef4af-7974-474e-9cbe-29a4303bc2ea",
            "userVideoCreationDate": "2024-12-16T14:50:13.164Z",
            "videoHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 0.991,
                    "seconds": 95,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "317ed885-b719-49be-9fcd-ad1888a29920"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T10:34:07.961Z",
            "playlistHistories": [],
            "userProfileId": "4057c052-1c92-4c06-81e2-5021a3591d3e",
            "userVideoCreationDate": "2024-12-17T10:34:07.961Z",
            "videoHistories": []
        },
        "userVideoId": "0b00fa9b-1945-4278-8ff4-958dff5b0cc6"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T10:08:42.777Z",
            "playlistHistories": [],
            "userProfileId": "47c116a1-8fad-4cf2-9758-7a31b8eb62ba",
            "userVideoCreationDate": "2024-12-17T10:08:42.777Z",
            "videoHistories": []
        },
        "userVideoId": "610861aa-3e66-432e-adbd-c60bf0e94832"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T10:08:00.354Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "bdc434f1-0d2f-41d9-9f2b-bcd6bd41fa65",
            "userVideoCreationDate": "2024-12-17T09:51:58.397Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.998,
                    "seconds": 295,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "a719613c-a487-4e60-a49e-b650ae609474"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:48:23.333Z",
            "playlistHistories": [],
            "userProfileId": "244abfc8-03f9-48e6-bc6f-1622adafbf4d",
            "userVideoCreationDate": "2024-12-17T09:48:23.333Z",
            "videoHistories": []
        },
        "userVideoId": "d6aa207b-de90-4637-84cb-6da6510f7ddf"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:48:13.151Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a1b84dbf-a4a9-44ef-9f10-9a558c578d73",
                    "seconds": 0,
                    "videoIds": [],
                    "watching": true
                }
            ],
            "userProfileId": "5b17a88a-c364-4192-9be3-111cb626c3b9",
            "userVideoCreationDate": "2024-12-17T09:43:19.460Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.112,
                    "seconds": 29,
                    "videoId": "4625f3d0-d6d6-4f72-a304-63e56be1a8e8",
                    "watching": false
                }
            ]
        },
        "userVideoId": "8f80ff6a-de05-4cd1-a805-1dd06bd9e525"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:47:56.741Z",
            "playlistHistories": [],
            "userProfileId": "39d2e74c-73bd-4820-9c26-359194997eb2",
            "userVideoCreationDate": "2024-12-17T09:47:56.741Z",
            "videoHistories": []
        },
        "userVideoId": "d618ebb1-1509-49b2-aeaa-9c9108f8d3fe"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:45:47.337Z",
            "playlistHistories": [],
            "userProfileId": "48983a18-fc6e-46a0-a5ef-0cbbcc3103cf",
            "userVideoCreationDate": "2024-12-17T09:45:47.337Z",
            "videoHistories": []
        },
        "userVideoId": "5470c5ed-b87e-4ee9-a75a-c4b40bb4a8fb"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:45:46.977Z",
            "playlistHistories": [],
            "userProfileId": "a2b76433-ef61-47b5-ad0b-d8bee0f17149",
            "userVideoCreationDate": "2024-12-17T09:45:46.977Z",
            "videoHistories": []
        },
        "userVideoId": "e75580dd-c66b-4087-9e9e-0a5b23c3f4d9"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:43:03.012Z",
            "playlistHistories": [],
            "userProfileId": "7e47f79f-4196-4530-8842-f2708294af42",
            "userVideoCreationDate": "2024-12-17T09:43:03.012Z",
            "videoHistories": []
        },
        "userVideoId": "99009344-ebe1-43a1-ad38-0d333ace9afc"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:38:41.439Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [],
                    "watching": true
                }
            ],
            "userProfileId": "e8d7af2a-21a3-405e-a1e5-08ec090e9ea7",
            "userVideoCreationDate": "2024-12-16T14:04:24.199Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.002,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": true
                }
            ]
        },
        "userVideoId": "a2cfc1d8-8f4f-4942-a52d-d21cc2ad862b"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:34:22.480Z",
            "playlistHistories": [],
            "userProfileId": "fd420d12-fe5d-42f7-a5ce-1759374e9c1c",
            "userVideoCreationDate": "2024-12-17T09:34:22.480Z",
            "videoHistories": []
        },
        "userVideoId": "e454f0b5-3aec-4e7e-9770-0220a18fd2f1"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:18:51.732Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "b4ada816-ce82-44f8-8c1d-e58772d5e449",
                    "seconds": 0,
                    "videoIds": [
                        "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                        "669d5450-eaf0-4470-b80d-152b8dd27695",
                        "efa5d7e9-ad79-411a-beac-78bcba8fc602"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "a0633d8b-1c2b-4e35-9861-ca690dff43a5",
            "userVideoCreationDate": "2024-12-17T09:04:14.705Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.862,
                    "seconds": 98,
                    "videoId": "f7b1a8d7-d6a3-4953-9e00-29ce0228183f",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "efa5d7e9-ad79-411a-beac-78bcba8fc602",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "669d5450-eaf0-4470-b80d-152b8dd27695",
                    "watching": false
                },
                {
                    "alreadyCompleted": false,
                    "percentage": 0.478,
                    "seconds": 67,
                    "videoId": "62a0bd71-170c-4c78-9a11-066607379d7f",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                    "watching": false
                }
            ]
        },
        "userVideoId": "ed11e329-51c7-4bbc-868e-641d36d25ce9"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T09:10:01.369Z",
            "playlistHistories": [],
            "userProfileId": "36bd97b0-5b2f-4de8-bc21-8c93963af65f",
            "userVideoCreationDate": "2024-12-17T09:10:01.369Z",
            "videoHistories": []
        },
        "userVideoId": "7f0e960e-e089-4bfe-9e42-62280d626f78"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T08:49:29.557Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "12aaba35-b12d-4add-8414-dbcbf2ef154d",
            "userVideoCreationDate": "2024-12-16T15:28:44.741Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.996,
                    "seconds": 295,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "0046c90f-ceab-407b-a1a4-9427f8200af0"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T08:47:07.255Z",
            "playlistHistories": [],
            "userProfileId": "d59672ab-e534-4c22-ae69-989eb88e57e0",
            "userVideoCreationDate": "2024-12-17T08:47:07.255Z",
            "videoHistories": []
        },
        "userVideoId": "0d6cd156-7fc3-4c9f-a194-f9f952c2a3d7"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T08:38:37.707Z",
            "playlistHistories": [],
            "userProfileId": "114c60ae-280a-43c4-a78e-a7ecdf12d92e",
            "userVideoCreationDate": "2024-12-17T08:38:37.707Z",
            "videoHistories": []
        },
        "userVideoId": "86ba5ba2-b9ed-4128-b4e9-40b72dcd49a5"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T08:08:19.466Z",
            "playlistHistories": [],
            "userProfileId": "45d79abf-9153-4c10-a378-da0eec47cf2c",
            "userVideoCreationDate": "2024-12-17T08:08:19.466Z",
            "videoHistories": []
        },
        "userVideoId": "a2009324-2690-4ff5-8c38-8a526b1eed63"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:43:45.269Z",
            "playlistHistories": [],
            "userProfileId": "9f333741-a001-4614-aa45-828772dd4b15",
            "userVideoCreationDate": "2024-12-17T07:43:45.269Z",
            "videoHistories": []
        },
        "userVideoId": "c1f97e62-ef5b-4b6e-ba1c-67e7646909d3"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:42:19.826Z",
            "playlistHistories": [],
            "userProfileId": "7d783b06-491c-48ce-a8ae-94f1ab257e26",
            "userVideoCreationDate": "2024-12-17T07:42:19.826Z",
            "videoHistories": []
        },
        "userVideoId": "964b29e0-7967-403e-9a49-1feba1495250"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:30:51.119Z",
            "playlistHistories": [],
            "userProfileId": "1eefb754-9b93-4969-8781-635e4741ea6e",
            "userVideoCreationDate": "2024-12-17T07:30:51.119Z",
            "videoHistories": []
        },
        "userVideoId": "f94259e7-3ccf-4a09-8078-64a890fede86"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:29:58.786Z",
            "playlistHistories": [],
            "userProfileId": "e1f34193-39b9-4879-b220-27fd1c018635",
            "userVideoCreationDate": "2024-12-17T07:29:58.786Z",
            "videoHistories": []
        },
        "userVideoId": "f8bbc431-5f59-4d38-98eb-3abeb06f1ffc"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:28:47.782Z",
            "playlistHistories": [],
            "userProfileId": "110baba0-2b36-4bd4-b861-42ef38baf537",
            "userVideoCreationDate": "2024-12-17T07:28:47.782Z",
            "videoHistories": []
        },
        "userVideoId": "dbcaf84c-0577-4ee1-b63b-39a252ee8ccd"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:11:01.832Z",
            "playlistHistories": [],
            "userProfileId": "e595c45f-350f-440c-a2a0-03665c122502",
            "userVideoCreationDate": "2024-12-17T07:11:01.832Z",
            "videoHistories": []
        },
        "userVideoId": "57062087-825b-4ea0-bdb7-2a3402a34992"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:10:40.124Z",
            "playlistHistories": [],
            "userProfileId": "e8fdc77f-c896-49c0-8f22-7429e5c116f3",
            "userVideoCreationDate": "2024-12-17T07:10:40.124Z",
            "videoHistories": []
        },
        "userVideoId": "f885abf8-96bf-4acc-994e-465d28ce00c6"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:07:38.253Z",
            "playlistHistories": [],
            "userProfileId": "d7f82f88-9946-4b6e-b2b7-32f218032f9a",
            "userVideoCreationDate": "2024-12-17T07:07:38.253Z",
            "videoHistories": []
        },
        "userVideoId": "960eeac8-847b-4115-b6d6-830d23f9c734"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:01:54.347Z",
            "playlistHistories": [],
            "userProfileId": "09cde7cb-0013-41c1-a8cd-484ab11ebba1",
            "userVideoCreationDate": "2024-12-17T07:01:54.347Z",
            "videoHistories": []
        },
        "userVideoId": "387ccdfd-0bc0-4fcd-a462-95dc56398d8e"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T07:01:12.858Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "63641367-5d95-4851-89dd-02701dda428e",
            "userVideoCreationDate": "2024-12-16T14:35:58.445Z",
            "videoHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "8c719c06-d7db-41e0-ba3a-c8c201aa2b43",
                    "watching": false
                }
            ]
        },
        "userVideoId": "46b7898f-aff2-4cbd-839a-0aac71e2eb05"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T06:36:45.583Z",
            "playlistHistories": [],
            "userProfileId": "5c84972a-7dca-49f1-bbd1-6598d1863dde",
            "userVideoCreationDate": "2024-12-17T06:36:45.583Z",
            "videoHistories": []
        },
        "userVideoId": "2d93d0ea-e632-4d6b-98a9-2d5b2dc23813"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T06:13:35.102Z",
            "playlistHistories": [],
            "userProfileId": "fc539b08-ffc1-48ed-babc-e18f9f17c86b",
            "userVideoCreationDate": "2024-12-17T06:13:35.102Z",
            "videoHistories": []
        },
        "userVideoId": "895093dc-faf1-4d13-968f-abdb1eac5860"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T05:47:51.094Z",
            "playlistHistories": [],
            "userProfileId": "fe30020c-34e8-49f6-8ead-555b9cbb0f78",
            "userVideoCreationDate": "2024-12-17T05:47:51.094Z",
            "videoHistories": []
        },
        "userVideoId": "48f18f84-3915-4cec-89cd-dd8eda1fa88d"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-17T00:26:30.298Z",
            "playlistHistories": [],
            "userProfileId": "370cf6e7-4cc3-4939-8c11-89a34f63a8ef",
            "userVideoCreationDate": "2024-12-17T00:26:30.298Z",
            "videoHistories": []
        },
        "userVideoId": "349eae82-6db4-42ca-b57f-8fe1fbcaec51"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T17:47:24.043Z",
            "playlistHistories": [],
            "userProfileId": "bc2434e6-8c97-41c3-904a-37d8b727a57d",
            "userVideoCreationDate": "2024-12-16T17:47:24.043Z",
            "videoHistories": []
        },
        "userVideoId": "b2be8261-43c0-498a-9524-f1f412b33ce8"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T17:35:02.095Z",
            "playlistHistories": [],
            "userProfileId": "4212e4e5-ecc9-4adb-b52a-ec873237667f",
            "userVideoCreationDate": "2024-12-16T17:35:02.095Z",
            "videoHistories": []
        },
        "userVideoId": "9c3b3d6c-a2c3-40f0-9c4f-9888da732539"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:40:20.237Z",
            "playlistHistories": [],
            "userProfileId": "1740e66f-4cf4-4fa4-8a7f-3fc2c497de84",
            "userVideoCreationDate": "2024-12-16T16:40:20.237Z",
            "videoHistories": []
        },
        "userVideoId": "39df39ef-fb05-40b5-9860-58ec42a5d6f8"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:37:20.288Z",
            "playlistHistories": [],
            "userProfileId": "b6988c40-583f-4dbe-bc2e-84e9595d24fd",
            "userVideoCreationDate": "2024-12-16T16:37:20.288Z",
            "videoHistories": []
        },
        "userVideoId": "1a55efbb-638d-4dd4-9d89-44acfe204ca2"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:23:02.978Z",
            "playlistHistories": [],
            "userProfileId": "20889c52-245d-4b4a-8a1d-3cf45d764ff2",
            "userVideoCreationDate": "2024-12-16T16:23:02.978Z",
            "videoHistories": []
        },
        "userVideoId": "8bfb8a62-602c-472c-9bfb-c153fbc9bf8a"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:09:17.010Z",
            "playlistHistories": [],
            "userProfileId": "7bc16bb0-7c73-4379-bb52-1f0b826e3f80",
            "userVideoCreationDate": "2024-12-16T16:09:17.010Z",
            "videoHistories": []
        },
        "userVideoId": "51eecdd1-2b4e-4467-a93c-8821c1c447d9"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:07:55.500Z",
            "playlistHistories": [],
            "userProfileId": "41e5334e-5d9d-4462-814c-f6f3bd318307",
            "userVideoCreationDate": "2024-12-16T16:07:55.500Z",
            "videoHistories": []
        },
        "userVideoId": "9b6c239c-c348-497e-a696-7565b3d2478f"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:04:57.958Z",
            "playlistHistories": [],
            "userProfileId": "56669b64-c706-4066-8361-18bff0e604c9",
            "userVideoCreationDate": "2024-12-16T16:04:57.958Z",
            "videoHistories": []
        },
        "userVideoId": "c9c4b033-2527-44b8-9c67-e7d61d05a7da"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T16:02:17.072Z",
            "playlistHistories": [],
            "userProfileId": "f90ad456-cf4b-446c-8a31-90bcec2c2a3d",
            "userVideoCreationDate": "2024-12-16T16:02:17.072Z",
            "videoHistories": []
        },
        "userVideoId": "884cd64c-a73e-45e3-bbd4-4814cb7ad5db"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:52:25.424Z",
            "playlistHistories": [],
            "userProfileId": "c327fea2-4b6e-4511-bd54-b21df27b7b39",
            "userVideoCreationDate": "2024-12-16T15:52:25.424Z",
            "videoHistories": []
        },
        "userVideoId": "320d67d7-160d-4098-888a-a9a5de90cc5c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:41:48.331Z",
            "playlistHistories": [],
            "userProfileId": "81cf581f-c930-45ea-b678-0500f4d9c995",
            "userVideoCreationDate": "2024-12-16T15:41:48.331Z",
            "videoHistories": []
        },
        "userVideoId": "da6440a2-44d9-4c78-844a-a70633d38062"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:31:23.175Z",
            "playlistHistories": [],
            "userProfileId": "c5f7a710-b782-4b4c-a64e-5205a54917d3",
            "userVideoCreationDate": "2024-12-16T15:31:23.175Z",
            "videoHistories": []
        },
        "userVideoId": "eff6c1c0-bcf2-43b7-889a-6cc122953318"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:29:59.720Z",
            "playlistHistories": [],
            "userProfileId": "eda7ee2e-bd90-4797-a109-c26dbd4b9a43",
            "userVideoCreationDate": "2024-12-16T15:29:59.720Z",
            "videoHistories": []
        },
        "userVideoId": "4eb9a44b-f680-47a5-8c0b-608a18a25182"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:25:44.731Z",
            "playlistHistories": [],
            "userProfileId": "ebe6c594-3664-4935-b04e-8829f7784eab",
            "userVideoCreationDate": "2024-12-16T15:25:44.731Z",
            "videoHistories": []
        },
        "userVideoId": "a01d7f0f-2616-46f3-a500-6e59bfc7e3fa"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:24:52.181Z",
            "playlistHistories": [],
            "userProfileId": "bd68e19d-0d78-475c-82b1-c8f2bc1ff392",
            "userVideoCreationDate": "2024-12-16T15:24:52.181Z",
            "videoHistories": []
        },
        "userVideoId": "51d8530f-8f70-48c0-83de-403fb25cc7e5"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:17:31.261Z",
            "playlistHistories": [],
            "userProfileId": "c00d054d-45d2-4ad3-a5a8-907a95b0078e",
            "userVideoCreationDate": "2024-12-16T15:17:31.261Z",
            "videoHistories": []
        },
        "userVideoId": "3807315b-8d04-4aa8-b183-86ec06bbc435"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:16:01.423Z",
            "playlistHistories": [],
            "userProfileId": "e7f14138-bebf-4711-a80f-fb835ee6e844",
            "userVideoCreationDate": "2024-12-16T15:16:01.423Z",
            "videoHistories": []
        },
        "userVideoId": "8f8d9623-c20f-4261-8036-8c0fc824e4be"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:15:49.620Z",
            "playlistHistories": [],
            "userProfileId": "a9353c16-f496-4973-97e0-21d72468fbe3",
            "userVideoCreationDate": "2024-12-16T15:15:49.620Z",
            "videoHistories": []
        },
        "userVideoId": "f18ba58d-b906-40f2-98df-2339d0583332"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:15:02.415Z",
            "playlistHistories": [],
            "userProfileId": "591fcbaf-5637-48ae-b98f-60f7141b487e",
            "userVideoCreationDate": "2024-12-16T15:15:02.415Z",
            "videoHistories": []
        },
        "userVideoId": "c76ba3e4-190a-4942-b03c-32c7fcf51b3a"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:01:47.237Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "a3ac58c1-5241-45c1-9c4c-91b2cde2aee1",
                    "seconds": 0,
                    "videoIds": [
                        "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                        "c955979a-8886-4dca-88a0-9410cee5ce84",
                        "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                        "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                        "f98ea146-b80b-4233-b3de-d51ed4c40308",
                        "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                        "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                        "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                        "e86bdb28-d358-4a33-91ba-aa2044f40b73"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "b7b854e3-357d-4737-a0a3-3a80fd2b85dc",
            "userVideoCreationDate": "2024-12-16T14:32:21.884Z",
            "videoHistories": [
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "e86bdb28-d358-4a33-91ba-aa2044f40b73",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "a35a9e3c-60fa-4ace-ad13-e880e9832447",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2a5cc21d-1a71-4400-9411-bb94df8839e0",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "dc5552dc-ac64-413d-b3da-4f45ad6552ab",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "f98ea146-b80b-4233-b3de-d51ed4c40308",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "7bc2da34-f1cc-4aa2-a4aa-30d598a55477",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "2574c2b3-17f6-41f8-b7aa-e7e6d6e65bf1",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "c955979a-8886-4dca-88a0-9410cee5ce84",
                    "watching": false
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "bfde9d0f-5b0b-40d2-898b-63126a56b24b",
                    "watching": false
                }
            ]
        },
        "userVideoId": "d835f329-32c2-4eaa-a889-d5ad6f3abe1e"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:01:16.431Z",
            "playlistHistories": [],
            "userProfileId": "f48dd132-fd59-44d5-a2f9-c734e59692f2",
            "userVideoCreationDate": "2024-12-16T15:01:16.431Z",
            "videoHistories": []
        },
        "userVideoId": "8b34e9f9-f504-4c60-9997-2fa46311b52b"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:00:42.808Z",
            "playlistHistories": [],
            "userProfileId": "0aa893e8-b97b-4098-9219-a27a993a922d",
            "userVideoCreationDate": "2024-12-16T15:00:42.808Z",
            "videoHistories": []
        },
        "userVideoId": "f905f454-70da-4185-9b7d-e373ffc6c963"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T15:00:21.351Z",
            "playlistHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0,
                    "playlistId": "b4ada816-ce82-44f8-8c1d-e58772d5e449",
                    "seconds": 0,
                    "videoIds": [
                        "11bac179-4706-4ee2-ae18-2ad5a59676d8"
                    ],
                    "watching": true
                }
            ],
            "userProfileId": "42d9404d-07e4-4643-949d-fcb6158dd674",
            "userVideoCreationDate": "2024-12-16T14:54:47.276Z",
            "videoHistories": [
                {
                    "alreadyCompleted": false,
                    "percentage": 0.016,
                    "seconds": 2,
                    "videoId": "62a0bd71-170c-4c78-9a11-066607379d7f",
                    "watching": true
                },
                {
                    "alreadyCompleted": true,
                    "percentage": 1,
                    "seconds": 0,
                    "videoId": "11bac179-4706-4ee2-ae18-2ad5a59676d8",
                    "watching": false
                }
            ]
        },
        "userVideoId": "4003ee79-443a-4294-8806-5f4d75db3a9c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:59:18.874Z",
            "playlistHistories": [],
            "userProfileId": "a778c6a6-4fe5-4d39-bfa8-81cb9869623e",
            "userVideoCreationDate": "2024-12-16T14:59:18.874Z",
            "videoHistories": []
        },
        "userVideoId": "41425acf-75a0-4819-9a07-9a0ea3fab0d1"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:58:41.078Z",
            "playlistHistories": [],
            "userProfileId": "8c91e512-cf6c-4234-8777-d7084f64dd4f",
            "userVideoCreationDate": "2024-12-16T14:58:41.078Z",
            "videoHistories": []
        },
        "userVideoId": "7282fcc3-5fc0-415a-98e9-8e4b2b5474cf"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:54:29.252Z",
            "playlistHistories": [],
            "userProfileId": "75e209ba-3250-44fb-a4e0-146e233f2aec",
            "userVideoCreationDate": "2024-12-16T14:54:29.252Z",
            "videoHistories": []
        },
        "userVideoId": "2333537a-04fc-4dc2-a42f-c88a9d1a3e8f"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:50:54.046Z",
            "playlistHistories": [],
            "userProfileId": "06b243e8-f037-4e47-9a0f-8eb675ec64ad",
            "userVideoCreationDate": "2024-12-16T14:50:54.046Z",
            "videoHistories": []
        },
        "userVideoId": "e4b08ec2-d0b0-4a64-bed6-a80520ce9188"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:50:04.808Z",
            "playlistHistories": [],
            "userProfileId": "033ebace-a196-460c-b749-f4711531e286",
            "userVideoCreationDate": "2024-12-16T14:50:04.808Z",
            "videoHistories": []
        },
        "userVideoId": "3dbab523-e81f-4d61-aaf7-3fcc82b0c16c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:49:43.151Z",
            "playlistHistories": [],
            "userProfileId": "38034c17-2ec6-4cf9-831c-4ac923e90666",
            "userVideoCreationDate": "2024-12-16T14:49:43.151Z",
            "videoHistories": []
        },
        "userVideoId": "535d02d8-25a0-4bc4-bfbd-ef2b7d5b309a"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:49:21.403Z",
            "playlistHistories": [],
            "userProfileId": "66143825-1d3e-4996-823b-26e4bfeb7ba0",
            "userVideoCreationDate": "2024-12-16T14:49:21.403Z",
            "videoHistories": []
        },
        "userVideoId": "9cc67f40-40cb-4ec5-b3ca-df6e31c62c38"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:47:33.484Z",
            "playlistHistories": [],
            "userProfileId": "2002098e-fa7f-4a0c-ae3f-9d877eed43d2",
            "userVideoCreationDate": "2024-12-16T14:47:33.484Z",
            "videoHistories": []
        },
        "userVideoId": "8a1d3413-ca48-4ce7-8c6d-33f0c061bfec"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:45:52.747Z",
            "playlistHistories": [],
            "userProfileId": "1aca88a4-e19a-432f-9f35-259ee60c8efc",
            "userVideoCreationDate": "2024-12-16T14:45:52.747Z",
            "videoHistories": []
        },
        "userVideoId": "d9e88285-c1c9-4c0f-badb-de1d3b2ce796"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:45:16.380Z",
            "playlistHistories": [],
            "userProfileId": "9eaf7fc0-5c30-48f8-a34c-ae04a06e9feb",
            "userVideoCreationDate": "2024-12-16T14:45:16.380Z",
            "videoHistories": []
        },
        "userVideoId": "b6896f92-6dd0-475a-829e-8ca0c461c6ec"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:43:40.063Z",
            "playlistHistories": [],
            "userProfileId": "57fd53a8-d67f-4d6e-b907-9d4b9830e4e5",
            "userVideoCreationDate": "2024-12-16T14:43:40.063Z",
            "videoHistories": []
        },
        "userVideoId": "34b9a243-ae88-4f89-90d4-cba8b9c085e4"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:43:11.565Z",
            "playlistHistories": [],
            "userProfileId": "70b35ef0-de8b-40f2-95af-69fe0f2aa034",
            "userVideoCreationDate": "2024-12-16T14:43:11.565Z",
            "videoHistories": []
        },
        "userVideoId": "9eae8fc0-244b-4cf8-990d-3823168f10a1"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:42:51.920Z",
            "playlistHistories": [],
            "userProfileId": "06d2a8af-1ec1-4815-9154-22180326d8a1",
            "userVideoCreationDate": "2024-12-16T14:42:51.920Z",
            "videoHistories": []
        },
        "userVideoId": "eb6cb90a-d198-460a-a088-6bbe1e5302ee"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:37:51.961Z",
            "playlistHistories": [],
            "userProfileId": "40831ce0-aec7-4017-b799-255275f1e07b",
            "userVideoCreationDate": "2024-12-16T14:37:51.961Z",
            "videoHistories": []
        },
        "userVideoId": "78b8f5d5-dae9-49e2-8e66-da38d2503eca"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:36:36.683Z",
            "playlistHistories": [],
            "userProfileId": "1efcfc70-63e5-4a3e-b3fc-3a16c566ca56",
            "userVideoCreationDate": "2024-12-16T14:36:36.683Z",
            "videoHistories": []
        },
        "userVideoId": "492d15f2-756b-4649-989c-914acee32680"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:35:45.049Z",
            "playlistHistories": [],
            "userProfileId": "e916f20e-324c-430d-8a41-8981b7f8f194",
            "userVideoCreationDate": "2024-12-16T14:35:45.049Z",
            "videoHistories": []
        },
        "userVideoId": "bbf49e2c-9721-490b-800f-fb7a98e1a3ec"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:35:05.459Z",
            "playlistHistories": [],
            "userProfileId": "07b6ee90-6463-4907-bb0d-e20f2f4bf611",
            "userVideoCreationDate": "2024-12-16T14:35:05.459Z",
            "videoHistories": []
        },
        "userVideoId": "1cbb9c2c-8676-433c-8518-1dc07aed4ac0"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:35:04.119Z",
            "playlistHistories": [],
            "userProfileId": "0b5c9c40-14ad-4b47-b584-6a5eafd33b9c",
            "userVideoCreationDate": "2024-12-16T14:35:04.119Z",
            "videoHistories": []
        },
        "userVideoId": "47d98794-fe14-4570-b623-55ed424021a2"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:34:50.054Z",
            "playlistHistories": [],
            "userProfileId": "0d007d43-7b24-4aaf-bb5a-c2938e7f450b",
            "userVideoCreationDate": "2024-12-16T14:34:50.054Z",
            "videoHistories": []
        },
        "userVideoId": "3637fda3-be14-4e3a-824d-df39dc27d138"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:34:43.603Z",
            "playlistHistories": [],
            "userProfileId": "86d92ac9-43a0-465d-a1ec-8909e5c0dc81",
            "userVideoCreationDate": "2024-12-16T14:34:43.603Z",
            "videoHistories": []
        },
        "userVideoId": "343eb5da-7321-497e-a33f-8172c87b36e1"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:32:29.192Z",
            "playlistHistories": [],
            "userProfileId": "8dbe74f8-1fe3-4e23-9119-e037e0203357",
            "userVideoCreationDate": "2024-12-16T14:32:29.192Z",
            "videoHistories": []
        },
        "userVideoId": "aad48656-6a76-4a9c-8894-298f8acd7937"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:31:47.054Z",
            "playlistHistories": [],
            "userProfileId": "5aaa0f55-270f-452d-8380-28dbc6918209",
            "userVideoCreationDate": "2024-12-16T14:31:47.054Z",
            "videoHistories": []
        },
        "userVideoId": "a3ffc98b-02fa-49f5-a34a-96791f9802aa"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:31:43.865Z",
            "playlistHistories": [],
            "userProfileId": "9889a8d9-a5ad-4785-8766-e889bbb3f057",
            "userVideoCreationDate": "2024-12-16T14:31:43.865Z",
            "videoHistories": []
        },
        "userVideoId": "41b1a27c-c084-4bac-8640-11c189a50171"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:31:20.465Z",
            "playlistHistories": [],
            "userProfileId": "9fe8563f-0bfa-4d3b-8227-6702f2ab371d",
            "userVideoCreationDate": "2024-12-16T14:31:20.465Z",
            "videoHistories": []
        },
        "userVideoId": "05772ab0-7f38-4394-bf89-2925e97cd260"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:31:16.425Z",
            "playlistHistories": [],
            "userProfileId": "172acf6a-1c26-4a6c-8b69-b8f3d67532fe",
            "userVideoCreationDate": "2024-12-16T14:31:16.425Z",
            "videoHistories": []
        },
        "userVideoId": "957b5a00-9494-45c1-8619-72e47d5439ff"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:30:13.521Z",
            "playlistHistories": [],
            "userProfileId": "71d64bc8-9106-47cb-890d-4d9676859ca0",
            "userVideoCreationDate": "2024-12-16T14:30:13.521Z",
            "videoHistories": []
        },
        "userVideoId": "29dac579-9105-4c43-ab67-1d73f6b43359"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:26:20.563Z",
            "playlistHistories": [],
            "userProfileId": "81c44f92-04a3-4b45-a821-2b70b36d38f4",
            "userVideoCreationDate": "2024-12-16T14:26:20.563Z",
            "videoHistories": []
        },
        "userVideoId": "63e7b95e-ff01-4201-b0a2-0ffdd8c0f060"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:24:10.922Z",
            "playlistHistories": [],
            "userProfileId": "3b1c3704-4090-4ed7-a267-5fa5cb997a34",
            "userVideoCreationDate": "2024-12-16T14:24:10.922Z",
            "videoHistories": []
        },
        "userVideoId": "13b5fd43-52d1-4fbf-8a5f-f5840c47bbae"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:21:00.913Z",
            "playlistHistories": [],
            "userProfileId": "1e792314-f510-40f0-a997-342b6bfa0a90",
            "userVideoCreationDate": "2024-12-16T14:21:00.913Z",
            "videoHistories": []
        },
        "userVideoId": "d402f4dd-a94a-400d-8d0e-21a472c1c1e3"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:17:20.742Z",
            "playlistHistories": [],
            "userProfileId": "d1c574be-893f-400e-b674-6996a4d44968",
            "userVideoCreationDate": "2024-12-16T14:17:20.742Z",
            "videoHistories": []
        },
        "userVideoId": "927af93b-b477-4999-920e-8f28eb0d7948"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:15:38.984Z",
            "playlistHistories": [],
            "userProfileId": "42869e92-b80d-40c8-b372-381748b67cd2",
            "userVideoCreationDate": "2024-12-16T14:15:38.984Z",
            "videoHistories": []
        },
        "userVideoId": "6a28b3f4-9606-4cd8-b0a7-db9bb190545c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:15:01.941Z",
            "playlistHistories": [],
            "userProfileId": "f23dd673-fe45-4a08-ba07-78a67974a222",
            "userVideoCreationDate": "2024-12-16T14:15:01.941Z",
            "videoHistories": []
        },
        "userVideoId": "c8f13e10-ccdc-44f8-9d65-62efcfd203af"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:14:09.709Z",
            "playlistHistories": [],
            "userProfileId": "a84e2f07-2ba6-4e49-b949-4dafcf3791d8",
            "userVideoCreationDate": "2024-12-16T14:14:09.709Z",
            "videoHistories": []
        },
        "userVideoId": "3e3bd351-152f-406f-9f91-3dea4c0e68a2"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:12:01.675Z",
            "playlistHistories": [],
            "userProfileId": "bbd504d1-969c-4fc7-9a9b-51f40e76ef58",
            "userVideoCreationDate": "2024-12-16T14:12:01.675Z",
            "videoHistories": []
        },
        "userVideoId": "f468c94b-4a3d-4540-947b-2d3482262cd0"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:10:56.290Z",
            "playlistHistories": [],
            "userProfileId": "f41f90fa-fabd-4ad2-9282-4a61a6655723",
            "userVideoCreationDate": "2024-12-16T14:10:56.290Z",
            "videoHistories": []
        },
        "userVideoId": "5f1b7332-ac07-4d8a-98f2-959a7e84c813"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:10:50.229Z",
            "playlistHistories": [],
            "userProfileId": "1c37cc0a-3a72-477c-83f3-e52d437ca738",
            "userVideoCreationDate": "2024-12-16T14:10:50.229Z",
            "videoHistories": []
        },
        "userVideoId": "8dbd0e78-b164-43ef-ba55-1a6ca4d9adf2"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:09:53.035Z",
            "playlistHistories": [],
            "userProfileId": "45bedb1e-cf77-4c72-9999-c0773a67098f",
            "userVideoCreationDate": "2024-12-16T14:09:53.035Z",
            "videoHistories": []
        },
        "userVideoId": "a5dfe224-bf85-4937-831e-36cfd646c014"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:09:47.001Z",
            "playlistHistories": [],
            "userProfileId": "bca25207-6b4a-4671-96d7-2e97d0d58653",
            "userVideoCreationDate": "2024-12-16T14:09:47.001Z",
            "videoHistories": []
        },
        "userVideoId": "d3efd2a2-736f-4b78-ac45-3e79413639d7"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:09:15.482Z",
            "playlistHistories": [],
            "userProfileId": "ff65b06c-c63c-4b65-80cf-99011d98e03c",
            "userVideoCreationDate": "2024-12-16T14:09:15.482Z",
            "videoHistories": []
        },
        "userVideoId": "2dcd9ce0-42b2-418b-932d-1f881eafc7c3"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:08:10.853Z",
            "playlistHistories": [],
            "userProfileId": "fd878f93-f18e-410c-bfef-fcbbf453d01b",
            "userVideoCreationDate": "2024-12-16T14:08:10.853Z",
            "videoHistories": []
        },
        "userVideoId": "431bd41b-eeca-4b15-a41c-ae29d394ff15"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:06:19.780Z",
            "playlistHistories": [],
            "userProfileId": "ce4d190e-d1c2-4af7-ba5f-a2b8f526fb91",
            "userVideoCreationDate": "2024-12-16T14:06:19.780Z",
            "videoHistories": []
        },
        "userVideoId": "03395af9-6b71-444c-bd78-d93e1bb49771"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:05:56.504Z",
            "playlistHistories": [],
            "userProfileId": "db32f983-fd1e-4484-b123-e667b87d8d3e",
            "userVideoCreationDate": "2024-12-16T14:05:56.504Z",
            "videoHistories": []
        },
        "userVideoId": "e0a7eaf6-2343-4531-9f5c-e74374dc6c5d"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:03:57.477Z",
            "playlistHistories": [],
            "userProfileId": "1d9f9a10-db06-4d6b-80b7-89b886b5421f",
            "userVideoCreationDate": "2024-12-16T14:03:57.477Z",
            "videoHistories": []
        },
        "userVideoId": "e1f18b5b-259e-422d-bfc7-0ae3e496b756"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:02:31.815Z",
            "playlistHistories": [],
            "userProfileId": "5b6a0024-7c51-46b0-bc4c-6d67fa8f9f31",
            "userVideoCreationDate": "2024-12-16T14:02:31.815Z",
            "videoHistories": []
        },
        "userVideoId": "eded791c-e867-4adb-9a6c-ac28df78b87c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T14:02:00.766Z",
            "playlistHistories": [],
            "userProfileId": "a96fbca1-cc40-4274-9029-24f4d2d5ac2c",
            "userVideoCreationDate": "2024-12-16T14:02:00.766Z",
            "videoHistories": []
        },
        "userVideoId": "2ac50adb-ecf4-41a0-adf6-d9c828e1b7ea"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:58:58.260Z",
            "playlistHistories": [],
            "userProfileId": "68cecf6b-a381-43e8-87ad-cda3900894bf",
            "userVideoCreationDate": "2024-12-16T13:58:58.260Z",
            "videoHistories": []
        },
        "userVideoId": "0546020a-dbcb-46b8-8219-991e08f3d247"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:59.835Z",
            "playlistHistories": [],
            "userProfileId": "d53419bb-7e41-4029-b0df-03ef9c8684d1",
            "userVideoCreationDate": "2024-12-16T13:55:59.835Z",
            "videoHistories": []
        },
        "userVideoId": "17c9922f-bdf7-41a3-a0ed-2e69b557bc20"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:31.593Z",
            "playlistHistories": [],
            "userProfileId": "25967a57-9e8e-448f-8fa1-1bedcc3743ca",
            "userVideoCreationDate": "2024-12-16T13:55:31.593Z",
            "videoHistories": []
        },
        "userVideoId": "98e7c0c5-6ff3-4bd2-992e-f3f488d5370c"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:20.859Z",
            "playlistHistories": [],
            "userProfileId": "748e6fa2-cdb3-4533-a622-8c9d955eba70",
            "userVideoCreationDate": "2024-12-16T13:55:20.859Z",
            "videoHistories": []
        },
        "userVideoId": "24a0940b-c5ee-446d-8dcb-28d34cae8259"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:17.592Z",
            "playlistHistories": [],
            "userProfileId": "93de231f-b729-44bb-b956-02a052c4f503",
            "userVideoCreationDate": "2024-12-16T13:55:17.592Z",
            "videoHistories": []
        },
        "userVideoId": "e99009a6-2d7f-4795-9f46-8d3cde5cc360"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:13.295Z",
            "playlistHistories": [],
            "userProfileId": "f6e02786-6c59-4d12-a505-1e2de7b8cd74",
            "userVideoCreationDate": "2024-12-16T13:55:13.295Z",
            "videoHistories": []
        },
        "userVideoId": "ecc8d622-da00-4137-ba8c-1d0a6b9b3729"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:55:03.658Z",
            "playlistHistories": [],
            "userProfileId": "7acff567-44e1-41b6-b060-0e04d538f537",
            "userVideoCreationDate": "2024-12-16T13:55:03.658Z",
            "videoHistories": []
        },
        "userVideoId": "e4e3b2cb-faf9-4656-a7d2-4719e1db6856"
    },
    {
        "userVideoDetail": {
            "companyId": "6d0d0d03-b332-4057-99b2-06982ed097b4",
            "dateLastUpdate": "2024-12-16T13:28:41.928Z",
            "playlistHistories": [],
            "userProfileId": "e609a8db-cf4a-4d61-bb12-8315a98e04e2",
            "userVideoCreationDate": "2024-12-16T13:28:41.928Z",
            "videoHistories": []
        },
        "userVideoId": "427dceec-0eae-4a17-b4e8-49476349c36e"
    }
];


function UtilityPage() {
    const resultRef = useRef<HTMLDivElement>(null);
    useTitle('System Utilities');
    const playlists = useVideoStore((state) => state.playlists);
    useEffect(() => {
        if (!playlists) {
            useVideoStore.getState().initVideoStore();
        }
    }, [playlists]);
    const fixUserVideos = async () => {
        // Loop through all user videos, gather all the playlists ids that have alreadCompleted a true
        const playlistIds = userVideos.map((userVideo) => {
            return userVideo.userVideoDetail.playlistHistories.filter((playlistHistory) => {
                return playlistHistory.alreadyCompleted;
            }).map((playlistHistory) => {
                return playlistHistory.playlistId;
            });
        });
        //flatten the array
        const flattened = playlistIds.flat();
        //match them with their title in playlist
        const mapped = flattened.map((playlistId) => {
            const related = (playlists ?? []).find((playlist) => playlist.playlistId === playlistId);
            return "Playlist: " + related?.playlistDetail.title.it + " - " + playlistId;
        });

        //find the uservideos that are in those completed playlists
        

        // show result in ref
        if (resultRef.current) {
            resultRef.current.innerText = JSON.stringify(mapped, null, 4);
        }

        console.log(mapped);
    };

    return (
        <div className="d-flex flex-column align-items-start">
            <span>
                Playlists loaded: {playlists ? "Yes" : "No"}
            </span>
            <button onClick={fixUserVideos}>
                Sistema video
            </button>
            <code ref={resultRef}>
            </code>
        </div>
    )
}
export default UtilityPage