import React, { useEffect, useMemo, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import CircleButton from 'components/CircleButton';
import FloatingMenu from 'components/FloatingMenu';
import { useAuthStore } from 'stores/authStore';
import { Card, Nav, Offcanvas, Tab } from 'react-bootstrap';
import Config from 'utils/config';
import SectionTitle from 'components/SectionTitle';
import { MixedRow, VideoWidget } from 'pages/user_dashboard/UserDashbordWidgetRows';
import { DragDropContext, DropResult, ResponderProvided, Droppable, DroppableProvided, DroppableStateSnapshot, Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import toast from 'react-hot-toast';
import { QuizModule } from 'models/eduquizModels';
import { useTranslation } from 'react-i18next';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';
import { useChatStore } from 'stores/chatStore';
import { useCompanyStore } from 'stores/companyStore';
import { useEduquizStore } from 'stores/eduquizStore';
import { useExpertsStore } from 'stores/expertsStore';
import { useProgressionStore } from 'stores/progressionStore';
import { useUserStore } from 'stores/userStore';
import { DocumentObject, useDocumentStore } from 'stores/documentStore';
import { motion, AnimatePresence } from 'framer-motion';
import ProximityDate from 'components/ProximityDate';
import GemCounter from 'components/GemCounter';
import LifepointCounter from 'components/LifepointCounter';
import XpCounter from 'components/XpCounter';
import Mark from 'components/Mark';
import { useAppStore, useIsMobile } from 'stores/appStore';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';
import { useVideoStore } from 'stores/videoStore';

var sidebarStatus = useAppStore.getState().isMobile ? true : false;

const desktopSidebarPreference = localStorage.getItem('desktopSidebarPreference');

if (!desktopSidebarPreference) {
    localStorage.setItem('desktopSidebarPreference', "false");
} else {
    if (desktopSidebarPreference === "false" && !useAppStore.getState().isMobile) {
        sidebarStatus = false;
    } else {
        if (desktopSidebarPreference === "true" && !useAppStore.getState().isMobile) {
            sidebarStatus = true;
        }
    }
}

function SidebarsLayout(props: any) {
    const [sidebarOpen, setSidebarOpen] = React.useState(sidebarStatus);
    const [showDocumentSidebar, setShowDocumentSidebar] = React.useState(false);
    const [title, setTitle] = React.useState("Dashboard");
    const location = useLocation();
    const [userDocuments, setDocuments] = useState<DocumentObject[]>([]);
    const [isHome, setIsHome] = useState(false);
    const { t } = useTranslation();
    const headerWidget = useAppStore(state => state.headerWidget);
    const isMobile = useIsMobile();
    const unreadChats = useChatStore(state => state.unreadChats);

    const userVideo = useVideoStore(state => state.userVideo);
    const currentUser = useUserStore((state) => state.user);
    const chats = useChatStore((state) => state.chats);
    const activeChat = useChatStore((state) => state.activeChat);
    const argumentList = useEduquizStore(state => state.argumentList);
    const user = useUserStore(state => state.user);
    const expertsList = useExpertsStore(state => state.expertsList);
    const [editing, setEditing] = React.useState(false);
    const companyPreferences = useCompanyStore(state => state.currentCompany?.companyDetail.defaultDashboardPreferences);
    const initialWidgets = useMemo(() => {
        if (user?.userProfileDetail.dashboardPreferences && user?.userProfileDetail.dashboardPreferences.length > 0) {
            return user?.userProfileDetail.dashboardPreferences;
        } else if (companyPreferences && companyPreferences.length > 0) {
            return companyPreferences;
        }
        return Config.defaultDashboardPreferences;
    }, [user, companyPreferences]);

    const [widgets, setWidgets] = React.useState<string[]>(
        initialWidgets
    );
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /* Video fetcher */
    useEffect(() => {
        if (!userVideo) {
            useVideoStore.getState().initVideoStore();
        }
    }, [userVideo]);

    const latestPlaylist = useMemo(() => {
        if (userVideo && userVideo.userVideoDetail.playlistHistories[0]) {
            return userVideo.userVideoDetail.playlistHistories[0].playlistId;
        } else {
            return undefined;
        }
    }, [userVideo]);

    /* Docuemnts fetcher */
    useEffect(() => {
        const unsubscribe = useDocumentStore.subscribe((documentsStoreState) => {
            const docs = Object.values(documentsStoreState.documents).filter((doc) => doc.document.ownerId === useUserStore.getState().user?.userProfileId && doc.document.type === 'chat');
            setDocuments(docs);
        });
        useDocumentStore.getState().searchDocuments(useUserStore.getState().user?.userProfileId);
        return () => {
            unsubscribe();
        };
    }, []);

    /* Sidebar handler */
    React.useEffect(() => {
        if (isMobile) {
            setSidebarOpen(false);
        }

        if (location.pathname === "/" || location.pathname === "/welfare/dashboard") {
            setTitle("Home");
            setIsHome(true);

            /*             useAppStore.getState().setHeaderWidget(
                            <>
                                {isHome && <div className='position-relative cursor-pointer ms-auto flex-shrink-0' onClick={() =>
                                    setShowDocumentSidebar(!showDocumentSidebar)}>
                                    <span className='me-2 small text-body-tertiary d-none d-md-inline'>{t('dashboard:history')}</span>
                                    <CircleButton iconClassName={"fas fa-history"} variant="light" className='' iconVariant="secondary" onClick={() => null} />
                                    {
                                        unreadChats.length > 0 &&
                                        <i className='fas fa-circle text-danger position-absolute'
                                            style={{
                                                top: 0,
                                                right: "-3px",
                                                fontSize: "0.5rem"
                                            }}></i>
                                    }
                                </div>}
                            </> 
                        ); */
        } else {

            setIsHome(false);
            // get page title from dcoument, remove "FunniFin |" from it
            setTitle(document.title.replace("Funnifin |", "").trim());
        }
        // scroll SidebarsLayout_MainContainer to top
        document.querySelector('.SidebarsLayout_MainContainer')!.scrollTop = 0;
        // close the bottomsheet
        sheetRef.current?.snapTo(({ headerHeight }) => headerHeight)

    }, [location, isMobile, showDocumentSidebar, unreadChats.length, t]);
    /* showdocumentsidebar effect */
    useEffect(() => {
        if (showDocumentSidebar) {
            document.body.style.backgroundColor = "white";
        }
        else {
            // reset body backgroundColor
            document.body.style.backgroundColor = "var(--bs-body-bg)";
        }
        return () => {
            // reset body backgroundColor
            document.body.style.backgroundColor = "var(--bs-body-bg)";
        }
    }, [showDocumentSidebar]);

    // get distance from left side of window
    const [paddingLeft, setPaddingLeft] = useState(0);

    useEffect(() => {
        if (!isMobile) {
            localStorage.setItem('desktopSidebarPreference', sidebarOpen.toString());
        }
        if (sidebarOpen) {
            setPaddingLeft(64 + 28 + 18);
        } else {
            setPaddingLeft(288);
        }
    }, [sidebarOpen, isMobile]);

    useEffect(() => {
        if (currentUser) {
            useChatStore.getState().getChatData(currentUser);
        }
    }, [currentUser]);

    useEffect(() => {
        if (expertsList === null) {
            useExpertsStore.getState().initExpertsStore();
        }
    }, [expertsList]);

    useEffect(() => {
        if (!argumentList && !useAuthStore.getState().isSystem) {
            useEduquizStore.getState().initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? null);
        }
        // init Swiper:
    }, [argumentList]);

    const sheetRef = React.useRef<RefHandles>(null);

    const moduleList = argumentList?.map((argument) => argument.argumentDetail.modules ?? []).flat() ?? [] as QuizModule[];
    const notCompletedModuleList = moduleList.filter((mod) => mod.moduleDetail.userModule!.userModuleDetail.percentageCompletion < 100);
    notCompletedModuleList.sort((a, b) => b.moduleDetail.userModule!.userModuleDetail.percentageCompletion - a.moduleDetail.userModule!.userModuleDetail.percentageCompletion);
    const suggestedModule = notCompletedModuleList[0] ?? moduleList[0] ?? null;
    const suggestedExpert = expertsList && expertsList[0] ? expertsList[0] : null;

    const saveUserDashboardPreferences = async () => {
        toast.promise(useUserStore.getState().editUserProfileDetail({
            dashboardPreferences: widgets,
        }), {
            loading: t('common:saving'),
            success: t('common:saved'),
            error: t('common:error'),
        });
    }
    const resetUserDashboardPreferences = async () => {
        setWidgets(initialWidgets);
    }


    return (
        <>
            <div className="SidebarsLayout position-fixed top-0 start-0 w-100 h-100" >
                <style>
                    {`
                    .expandChatButtonOnHover{
                        transform 100ms ease-in-out 0s;
                        transform: scale(1);
                    }
                    .expandChatButtonOnHover:hover {
                        transform: scale(1.1);
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px, rgba(0, 0, 0, 0.2) 0px 4px 16px
                    }

                    .SidebarDesktopContainer.sidebarClosed{
                    padding-top: 5px !important;
                    }
                            .SidebarsLayout_MainContainer {
            overflow-y: auto !important;
            -webkit-overflow-scrolling: touch; /* This ensures smooth scrolling on iOS devices */
        }
                `
                    }
                </style>
                {/* STICKY HEADER - MOBILE */}
                {isMobile &&
                    <div className="bg-transparent d-block d-md-none" >
                        <div className="w-100 d-flex align-items-center pb-3 pt-3 container-fluid">
                            {activeChat && <i className="fa-duotone fa-times fa-2x ms-1 me-3 cursor-pointer" onClick={() => useChatStore.getState().switchActiveChat()} />}
                            <div onClick={() => setSidebarOpen(!sidebarOpen)} aria-controls="sidebar">
                                <CircleButton iconClassName={"fas fa-bars"} className="text-white" onClick={() => {
                                }} />
                            </div>
                            <h3 className="mb-0 text-body-emphasis text-truncate px-2">{(activeChat && isHome) ? activeChat.asyncChatDetail.title : title}</h3>
                            {headerWidget}
                        </div>
                        <h3 className="mb-0 text-body-emphasis text-truncate px-2">{(activeChat && isHome) ? activeChat.asyncChatDetail.title : title}</h3>
                        <div id="header-widget-portal" className='flex-fill d-flex flex-row'>

                        </div>
                    </div>
                }
                <div className="h-100 d-flex pt-md-3 w-100">
                    {/* SIDEBAR DESKTOP*/}
                    <div className={`SidebarDesktopContainer h-auto ms-3 mb-3 d-none card bg-body-tertiary border-0 d-md-flex rounded p-3 flex-shrink-0 ${sidebarOpen ? "sidebarClosed" : "sidebarOpen"}`} id="sidebar" style={{
                        height: "min-content"
                    }}>
                        <FloatingMenu />
                    </div>
                    <div className='bg-body-tertiary rounded-top-end rounded-bottom-end d-none d-md-flex align-items-center justify-content-center cursor-pointer' onClick={() => setSidebarOpen(!sidebarOpen)} style={{
                        position: 'relative',
                        top: "1rem",
                        height: "32px",
                        width: "18px",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    }}>
                        <i className={"fas fa-fw text-body-secondary mx-1 " + (sidebarOpen ? " fa-angle-right" : " fa-angle-left")}></i>
                    </div>

                    <div className={`h-100  position-relative flex-fill d-flex flex-column overflow-y-auto ${sidebarOpen ? "contentOpen" : "contentClosed"}`}>
                        {/* STICKY HEADER - DESKTOP */}
                        {!isMobile && <div className="SidebarsLayout_Header px-md-4 bg-body d-none d-md-block" >
                            <div className="d-flex align-items-center pb-3 pt-3">
                                {activeChat && isHome && <i className="fa-duotone fa-times fa-2x ms-1 me-3 cursor-pointer" onClick={() => useChatStore.getState().switchActiveChat()} />}

                                <h3 className="mb-0 text-body-emphasis text-truncate pe-3">{(activeChat && isHome) ? activeChat.asyncChatDetail.title : title}</h3>
                                <div id="header-widget-portal-desktop" className='flex-fill d-flex flex-row'>

                                </div>
                                {
                                    isHome && useAuthStore.getState().isUser && <HeaderWidgetPortal >
                                        <div className='position-relative cursor-pointer ms-auto flex-shrink-0' onClick={() =>
                                            setShowDocumentSidebar(!showDocumentSidebar)}>
                                            <span className='me-2 small text-body-tertiary d-none d-md-inline'>{t('dashboard:history')}</span>
                                            <CircleButton iconClassName={"fas fa-history"} variant="light" className='' iconVariant="secondary" onClick={() => null} />
                                            {
                                                unreadChats.length > 0 &&
                                                <i className='fas fa-circle text-danger position-absolute'
                                                    style={{
                                                        top: 0,
                                                        right: "-3px",
                                                        fontSize: "0.5rem"
                                                    }}></i>
                                            }
                                        </div>
                                    </HeaderWidgetPortal>
                                }
                            </div>
                        </div >}
                        <div className='SidebarsLayout_MainContainer position-relative flex-fill pt-0 px-3 px-md-4 overflow-y-auto overflow-x-hidden flex-grow-1' style={{
                            /* height: useAuthStore.getState().isUser ? (!isMobile ? 'calc(100% - 150px )' :
                                'calc(100% - 150px )') : '100%', */
                            maxHeight: useAuthStore.getState().isUser ? (!isMobile ? 'calc(100% - 150px )' :
                                'calc(100% - 150px )') : '100%'
                        }}>
                            <Outlet />
                            {/* DOCUMENT SIDEBAR */}
                            <Offcanvas show={showDocumentSidebar} placement="end" onHide={() => setShowDocumentSidebar(!showDocumentSidebar)}>
                                <Offcanvas.Body className='w-100 h-100 d-flex align-items-start p-0 bg-body' >
                                    <div className='flex-grow-1 mh-100'>
                                        <div className='container-fluid container-md w-100 pt-3'>
                                            <div className="SidebarsLayout_Header px-md-3 d-none d-md-block" style={{ position: 'relative', zIndex: 3000 }} >
                                                <div className="d-flex align-items-center pb-3 pt-3">
                                                    <div className='position-relative ms-auto'>
                                                        <CircleButton iconClassName={"fas  fa-times"} variant="light" iconVariant="secondary" onClick={() => setShowDocumentSidebar(!showDocumentSidebar)} />
                                                        {
                                                            unreadChats.length > 0 &&
                                                            <i className='fas fa-circle text-danger position-absolute'
                                                                style={{
                                                                    top: 0,
                                                                    right: "-3px",
                                                                    fontSize: "0.5rem"
                                                                }}></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* STICKY HEADER - MOBILE */}
                                            <div className="bg-transparent d-block d-md-none" >
                                                <div className="w-100 d-flex align-items-center container-fluid">
                                                    <div className='position-relative ms-auto'>
                                                        <CircleButton iconClassName={"fas fa-times"} variant="light" className='ms-auto' iconVariant="secondary" onClick={() => setShowDocumentSidebar(!showDocumentSidebar)} />
                                                        {
                                                            unreadChats.length > 0 &&
                                                            <i className='fas fa-circle text-danger position-absolute'
                                                                style={{
                                                                    top: 0,
                                                                    right: "-3px",
                                                                    fontSize: "0.5rem"
                                                                }}></i>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='container-fluid container-md'>
                                            <Tab.Container defaultActiveKey="tab1">
                                                <Nav variant="tabs" className="mt-3 tab-lean">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="tab1">
                                                            {t('user_dashboard:chat_history')}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="tab2">
                                                            {t('user_dashboard:your_files')}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="tab1" className='pt-3'>

                                                        {/* Content for Tab 1 */}
                                                        {chats && chats
                                                            .sort((a, b) => new Date(b.asyncChatDetail.dateLastUpdate ?? "").getTime() - new Date(a.asyncChatDetail.dateLastUpdate ?? "").getTime())
                                                            .map((chat, index) => (
                                                                <motion.div
                                                                    initial={{ x: 500, opacity: 0 }}
                                                                    animate={{ x: 0, opacity: 1 }}
                                                                    transition={{ type: "spring", delay: 0.05 + index * 0.1, duration: 0.1 }}
                                                                    key={chat.asyncChatId} className={"d-flex flex-row align-items-center p-3 border-bottom text-hoverable cursor-pointer " + (
                                                                        chat.asyncChatId === activeChat?.asyncChatId ? "text-primary" : ""
                                                                    )}
                                                                    onClick={() => {
                                                                        if (chat.asyncChatId !== activeChat?.asyncChatId) {
                                                                            useChatStore.getState().switchActiveChat(chat);
                                                                            setShowDocumentSidebar(false);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="d-flex flex-column">
                                                                        <strong>{chat.asyncChatDetail.title}</strong>
                                                                    </div>
                                                                    {unreadChats.includes(chat.asyncChatId) && <div className='mx-3'>
                                                                        <Mark>NEW</Mark>
                                                                    </div>}
                                                                    <div className="ms-auto flex-shrink-0 ps-2">
                                                                        <strong className="fw-normal small">
                                                                            <ProximityDate date={new Date(chat.asyncChatDetail.dateLastUpdate ?? "")} />
                                                                        </strong>
                                                                        <CircleButton iconClassName='fas fa-trash' className='ms-3' variant="transparent" iconVariant='body-secondary' onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            useChatStore.getState().closeChat(chat);
                                                                        }} />
                                                                    </div>
                                                                </motion.div>
                                                            ))}
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="tab2" className='pt-3'>
                                                        <AnimatePresence>
                                                            {userDocuments && userDocuments.sort(
                                                                (a, b) => new Date(b.document.creationDate ?? "").getTime() - new Date(a.document.creationDate ?? "").getTime()
                                                            ).map((doc) => (
                                                                <DocumentTile key={doc.documentId} doc={doc} />
                                                            ))}
                                                        </AnimatePresence>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                            <style>
                                {`
                [data-rsbs-header]{
                box-shadow: none !important;}
                                    [data-rsbs-overlay] {
                                        transition: all 0.4s ease-out;
                                    }
                @media (min-width: 768px) {
                    [data-rsbs-overlay] {
                        left: calc(${paddingLeft}px + 16px + 26px);
                        width: calc(100vw - ${paddingLeft}px - 38px - 32px);
                    }
                }
[data-rsbs-overlay] {
background-color: var(--bs-body-bg) !important;
}
                `}

                            </style>

                            <BottomSheet
                                ref={sheetRef}
                                open={useAuthStore.getState().isUser ? (isHome ? (activeChat === null) : true) : false}
                                scrollLocking={false}
                                onSpringStart={() => {
                                    requestAnimationFrame(() => {
                                        if ((sheetRef.current?.height ?? 1000) > 72) {
                                            if (!show) {
                                                handleShow();
                                            }
                                        } else {
                                            if (show)
                                                handleClose();
                                        }
                                    }
                                    )
                                }}
                                blocking={
                                    /* (sheetRef?.current?.height ?? 1000) > 68 ?? false */
                                    show
                                }
                                onDismiss={() => {
                                    if (show) {
                                        handleClose();
                                        sheetRef.current?.snapTo(({ headerHeight }) => headerHeight);
                                    }
                                }}
                                style={{ right: 0 }}
                                snapPoints={({ maxHeight, headerHeight }) => [headerHeight, maxHeight - (
                                    document.body.clientWidth > 768 ? 80 : 64
                                )]}
                                className="bg-body"
                                defaultSnap={({ headerHeight }) => headerHeight}
                                header={<div className='d-flex flex-row align-items-center mb-2 cursor-pointer' onClick={() => {
                                    if (show) {
                                        handleClose();
                                        sheetRef.current?.snapTo(({ headerHeight }) => headerHeight);

                                    } else {
                                        sheetRef.current?.snapTo(({ maxHeight }) => maxHeight - (
                                            document.body.clientWidth > 768 ? 80 : 64
                                        ))
                                    }
                                }}>
                                    <>
                                        <button className='btn btn-primary bg-primary-subtle text-primary border-0 ms-auto'>
                                            {t('user_dashboard:widget')}
                                            <i className={'fas fa-chevron-up ms-2 ' + (show ? "fa-rotate-180" : "")}></i>
                                        </button>
                                    </>
                                </div>}
                                expandOnContentDrag={true}
                            >
                                <div className='px-3 d-flex flex-column'>

                                    {/* WIDGET SELECTOR */}
                                    <SectionTitle title={t('user_dashboard:widget')} className="mb-3">
                                        {/* Select preferred widget dropdown */}
                                        <div className="dropdown ms-auto pe-1">
                                            {!editing &&
                                                <strong className="text-primary text-uppercase small cursor-pointer" onClick={() => setEditing(true)}>
                                                    {t('common:edit')}
                                                </strong>}
                                            {
                                                editing &&
                                                <strong className="text-danger text-uppercase small cursor-pointer" onClick={() => {
                                                    setEditing(false);
                                                    resetUserDashboardPreferences();
                                                }}>
                                                    {t('common:cancel')}
                                                </strong>
                                            }
                                            {editing &&
                                                <strong className="text-primary ms-3 text-uppercase small cursor-pointer" onClick={() => {
                                                    setEditing(false);
                                                    saveUserDashboardPreferences();
                                                }}>
                                                    {t('common:save')}
                                                </strong>}
                                        </div>
                                    </SectionTitle>
                                    <DragDropContext
                                        onDragEnd={(result: DropResult, provided: ResponderProvided) => {
                                            if (!result.destination) {
                                                return;
                                            }
                                            const items = Array.from(widgets);
                                            const [reorderedItem] = items.splice(result.source.index, 1);
                                            items.splice(result.destination.index, 0, reorderedItem);
                                            setWidgets(items);
                                        }}
                                    >
                                        <Droppable droppableId="droppable" type='ROW'>
                                            {(provided: DroppableProvided, snapshotDroppable: DroppableStateSnapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={{
                                                        /* backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparent' */
                                                    }}

                                                    className={
                                                        "d-flex flex-column " + (editing ? 'wobble-children user-select-none' : '')
                                                    }
                                                    {...provided.droppableProps}
                                                >
                                                    {/* {snapshot.isDraggingOver && provided.placeholder} */}
                                                    {widgets.map((widget, index) => (
                                                        <Draggable key={widget} draggableId={widget} index={index} isDragDisabled={!editing}>
                                                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={
                                                                        !snapshotDroppable.draggingFromThisWith ? {
                                                                            animationDelay: "-.3s",
                                                                            animationDuration: ".4s",
                                                                        }
                                                                            :
                                                                            {
                                                                                ...provided.draggableProps?.style,
                                                                            }}
                                                                >

                                                                    {widget === 'mixed' && <MixedRow suggestedModule={suggestedModule} suggestedExpert={suggestedExpert} />}
                                                                    {widget === 'video' && <VideoWidget
                                                                        playlistId={latestPlaylist}
                                                                    />}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    {/* SPACER */}
                                    <div className='p-3'></div>
                                </div>
                            </BottomSheet>
                        </div>
                        {useAuthStore.getState().isUser && <div className='position-relative p-3 p-md-4 my-md-auto'>
                            {
                                isHome && <small className="text-body-tertiary text-center"
                                >
                                    {t('chat:ai_disclaimer')}
                                    <Link to="/welfare/experts" className="text-primary ms-1">
                                        {t('chat:ai_disclaimer_link')}
                                    </Link>
                                </small>
                            }

                        </div>}
                    </div>
                </div>
            </div >
            {/* MENU */}
            < Offcanvas show={sidebarOpen && isMobile
            } placement="start" className="d-flex d-md-none align-items-center rounded px-3 w-100 bg-transparent border-0" onHide={() => setSidebarOpen(!sidebarOpen)}>
                <Offcanvas.Body className='w-100'>
                    <Card>
                        <Card.Body>
                            <button type="button" className="btn-close text-reset ms-auto d-block" aria-label="Close" onClick={() => setSidebarOpen(!sidebarOpen)} />
                            <FloatingMenu />
                        </Card.Body>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas >
            {/* Floating chat button */}
        </>
    )
}

function DocumentTile({ doc }: { doc: DocumentObject }) {
    return (<motion.div
        /*         initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -400, opacity: 0 }} */
        key={doc.documentId} className="d-flex flex-row align-items-center p-3 border-bottom text-hoverable cursor-pointer " onClick={() => {
            if (navigator.userAgent.indexOf("Chrome") !== -1) {
                const link = document.createElement("a");
                link.href = Config.fileStorageBaseUrl + doc.document.storagePath;
                link.target = "_blank";
                link.download = doc.document.title ?? "";
                link.click();
            } else {
                window.open(Config.fileStorageBaseUrl + doc.document.storagePath, "_blank");
            }
        }}>
        {doc.document.type === 'application/pdf' && <i className="fas me-2 fa-file-pdf" />}
        {doc.document.type !== 'application/pdf' && <i className="fas me-2 fa-file-image" />}
        <strong className='text-truncate' style={{ maxWidth: "250px" }}>{doc.document.title}</strong>
        <div className="ms-auto ps-2" style={{ width: "50px" }}>
            <strong className="fw-normal small">{doc.document.creationDate?.split('T')[0]}</strong>
        </div>
    </motion.div>);
}


export default SidebarsLayout