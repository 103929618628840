import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import { use } from 'i18next';
import { QuizModule, UserModule } from 'models/eduquizModels';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'Routes';
import { useAuthStore } from 'stores/authStore';
import { useCompanyStore } from 'stores/companyStore';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useEduquizStore } from 'stores/eduquizStore';
import { useI18nStore } from 'stores/i18nStore';
import { useUsersStore } from 'stores/usersStore';
import { UserProgressionData, useUserStore } from 'stores/userStore';
import { useTitle } from 'utils/functions';

function UserDetailPage() {
    // get user from params
    const { userId } = useParams();
    const users = useUsersStore(state => (state.usersList));
    const userInfo = (users ?? []).find(user => user.userProfileId === userId)
    const [userStats, setUserStats] = useState<UserProgressionData | null>(null);
    useEffect(() => {
        if (!userStats)
            useUserStore.getState().getUserQuizStatistics(userId).then(setUserStats);
    }, [userStats, userId]);
    const argumentsList = useEduquizManagerStore(state => state.argumentList);
    useEffect(() => {
        if (!argumentsList) {
            useEduquizManagerStore.getState().initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? "");
        }
    }, [argumentsList]);
    useEffect(() => {
        if (!users)
            useUsersStore.getState().initUsers();
    }, [users]);
    const modules = argumentsList?.map(argument => argument.argumentDetail.modules).flat();
    const moduleInfo = (module: QuizModule) => (userStats?.userModules ?? []).find(userModule => userModule.userModuleDetail.moduleId === module.moduleId);
    console.log(userStats?.userUnits);


    const correctAnswersPerModule = (module: QuizModule) => {
        if (!module.moduleDetail.units) return 0;
        // get all the ids of all questions
        const questionsIds = module.moduleDetail.units?.map(unit => unit.unitDetail.questionIds).flat() ?? [];
        // filter all questions from userQuestions
        const userQuestions = (userStats?.userQuestions ?? []).filter(userQuestion => questionsIds.includes(userQuestion.userQuestionDetail.questionId));
        // reduce the number of instances of answeredOnceCorrectly
        return userQuestions.reduce((acc, userQuestion) => acc + (userQuestion.userQuestionDetail.answeredOnceCorrectly ? 1 : 0), 0);
    }
    const totalQuestionsAnsweredPerModule = (module: QuizModule) => {
        if (!module.moduleDetail.units) return 0;
        // get all the ids of all questions
        const questionsIds = module.moduleDetail.units?.map(unit => unit.unitDetail.questionIds).flat() ?? [];
        // filter all questions from userQuestions
        const userQuestions = (userStats?.userQuestions ?? []).filter(userQuestion => questionsIds.includes(userQuestion.userQuestionDetail.questionId));
        return userQuestions.length; /* userQuestions.reduce((acc, userQuestion) => acc + (userQuestion.userQuestionDetail.answered ? 1 : 0), 0); */
    }
    if (userStats) {
        console.log(userStats!.userQuestions!.map((q) => {
            return "Answered :" + q.userQuestionDetail.answered + " Correct: " + q.userQuestionDetail.answeredOnceCorrectly;
        }));

    }


    useTitle('Registro');
    const getTranslation = useI18nStore(state => state.getTranslation);
    const navigate = useNavigate();
    if (!userInfo || !argumentsList || !userStats) return <><Loader /></>
    return (
        <section className='UserDetailPage'>
            <div className='d-flex align-items-center mb-3'>
                <CircleButton className='text-white' iconClassName={"fas fa-angle-left"} onClick={() => {
                    console.log(routes.users());
                    navigate(routes.users());
                }} />
                <h3 className='flex-grow-1 ms-2 mb-0'>{userInfo?.userProfileDetail.name} {userInfo?.userProfileDetail.surname}</h3>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Argomento</th>
                                <th>Modulo</th>
                                <th>Risposte corrette</th>
                                <th>Risposte totali</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                argumentsList?.map(argument => <>
                                    {
                                        argument.argumentDetail.modules?.map((module, index) => <tr key={index}>
                                            <td>{getTranslation(argument.argumentDetail.title)}</td>
                                            <td>{getTranslation(module?.moduleDetail.title)}</td>
                                            <td>
                                                {correctAnswersPerModule(module)}
                                            </td>
                                            <td>
                                                {totalQuestionsAnsweredPerModule(module)}
                                            </td>
                                            {/*             <td>{module.correctQuestions}</td>
                                        <td>{module.totalQuestions}</td> */}
                                        </tr>)
                                    }
                                </>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default UserDetailPage