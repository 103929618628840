import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEduquizManagerStore } from 'stores/eduquizManagerStore';
import { useUsersStore } from 'stores/usersStore';
import { useI18nStore } from 'stores/i18nStore';
import HeaderAdmin from 'components/HeaderAdmin';
import CircleButton from 'components/CircleButton';
import { useCompanyStore } from 'stores/companyStore';
import SectionTitle from 'components/SectionTitle';
import Loader from 'components/Loader';
import { routes } from 'Routes';

let initializing = false;

export default function TopArgumentsPage() {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const getTranslation = useI18nStore(state => state.getTranslation);

    // Get the list of users and sort them by registration date
    const usersList = useUsersStore(state => state.usersList?.sort((a, b) => (a.userProfileDetail.registrationDate > b.userProfileDetail.registrationDate) ? -1 : 1));
    const initUsers = useUsersStore((state) => state.initUsers);

    // This effect is used to initialize the users list
    useEffect(() => {
        console.log('🏠 Dashboard page: init user list');
        if (!usersList && !initializing) {
            initializing = true;
            console.log('🏠 Dashboard page: useEffect: initUsers');
            initUsers().then(() => initializing = false);
        }
    }, [usersList, initUsers]);

    // Get the list of arguments
    const argumentList = useEduquizManagerStore(state => state.argumentList);
    const initEduquiz = useEduquizManagerStore((state) => state.initEduquiz);

    // This effect is used to initialize the arguments list
    useEffect(() => {
        if (!argumentList) {
            initEduquiz(useCompanyStore.getState().currentCompany?.companyId ?? "");
        } else {
            console.log('🏠 Dashboard page: useEffect: argumentList', argumentList);
        }
    }, [argumentList, initEduquiz]);

    const modules = useMemo(() => (argumentList ?? [])?.flatMap((argument) => argument?.argumentDetail.modules).filter(m => m !== undefined).sort((a, b) => (a.moduleDetail.totalCompleted > b.moduleDetail.totalCompleted) ? -1 : 1),
        [argumentList]);
    const units = useMemo(() => modules?.flatMap((module) => module?.moduleDetail.units).filter(u => u !== undefined).sort((a, b) => (a.moduleDetail?.totalCompleted > b.moduleDetail?.totalCompleted) ? -1 : 1),
        [modules]);
    return (<section className='DashboardPage d-flex pt-3 flex-column'>
        <HeaderAdmin title='dashboard:title' />
        <div className="d-flex flex-column">
            <div className="card-title d-flex align-items-center mb-3">
                <CircleButton className="me-3" iconClassName="fas fa-arrow-left" onClick={() => navigate(routes.dashboard())}></CircleButton>
                <h4 className="mb-0">{t('common:top')} {t('common:arguments')}</h4>
            </div>
            <div className="d-flex flex-column ">
                {argumentList && <div className='row mb-5'>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped tablie-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-body-tertiary'>{t('common:argument')}</th>
                                                <th scope="col" className='text-center text-body-tertiary'>#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {argumentList.sort((a, b) => (a.argumentDetail.totalCompleted > b.argumentDetail.totalCompleted) ? -1 : 1)
                                                .map((argument) =>
                                                    <tr key={argument.argumentId}>
                                                        <th scope="row">{getTranslation(argument.argumentDetail.title)}</th>
                                                        <td className='text-center'>{argument.argumentDetail.totalCompleted}</td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    !argumentList && <Loader />
                }
                <SectionTitle title={t('common:top') + " " + t('common:modules')}></SectionTitle>
                {argumentList && <div className='row mb-5'>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped tablie-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-body-tertiary'>{t('common:module')}</th>
                                                <th scope="col" className='text-center text-body-tertiary'>#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modules
                                                .map((module) => {
                                                    return (
                                                        <tr key={module.moduleId}>
                                                            <th scope="row">{getTranslation(module.moduleDetail.title)}</th>
                                                            <td className='text-center'>{module.moduleDetail.totalCompleted}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    !argumentList && <Loader />
                }
                <SectionTitle title={t('common:top') + " " + t('common:units')}></SectionTitle>
                {argumentList && <div className='row mb-5'>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-body-tertiary'>{t('common:unit')}</th>
                                                <th scope="col" className='text-center text-body-tertiary'>#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {argumentList && units
                                                .map((unit) => {
                                                    return (
                                                        <tr key={unit.unitId}>
                                                            <th scope="row">{getTranslation(unit.unitDetail.title)}</th>
                                                            <td className='text-center'>{unit.unitDetail.totalCompleted}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {
                    !argumentList && <Loader />
                }
            </div>
            {/*             <DashboardPageCard rows="3" />
            <DashboardPageCard rows="3" />
            <DashboardPageCard rows="6" />
            <DashboardPageCard rows="12" /> */}
        </div>
    </section >
    )
}