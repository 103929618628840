import CircleButton from 'components/CircleButton';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useI18nStore } from 'stores/i18nStore';
import { useShopManagerStore } from 'stores/shopManagerStore';
import Config from 'utils/config';
import { getFlagEmoji, useTitle } from 'utils/functions';
import { Modal, Button, Form, Nav, Tab, Tabs } from 'react-bootstrap';
import HeaderWidgetPortal from 'components/HeaderWidgetPortal';
import { ShopItem } from 'models/shopModels';

function ShopManagerPage() {
    const shopItems = useShopManagerStore((state) => state.shopItems);
    const initShopManager = useShopManagerStore((state) => state.initShopManager);
    const deleteShopItem = useShopManagerStore((state) => state.deleteShopItem);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editShopItem, setEditShopItem] = useState<ShopItem | null>(null);
    //i18n
    const { t } = useTranslation();

    // This effect is used to initialize the shopItems list
    useEffect(() => {
        if (shopItems === null) {
            console.log('⬅️ ShopManagerPage: useEffect: initShop');
            initShopManager();
        }
    }, [shopItems, initShopManager]);
    useTitle(t('shop_manager:title'));
    // If the shopItems is not initialized yet, we show a loading indicator
    if (shopItems === null || shopItems === undefined) return (<Loader />);

    // Else return the shopItems list
    return (
        <section className='ShopManagerPage d-flex flex-column'>
            <HeaderWidgetPortal>
                <CircleButton onClick={() => setIsModalOpen(true)} className={'ms-auto'} iconClassName='fas fa-plus' />
            </HeaderWidgetPortal>
            <div className="card mb-3">
                <div className="card-body">
                    {
                        shopItems && shopItems.length > 0 &&
                        <table className="table table-hover table-responsive table-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" className='col-1'>{t('forms:company')}</th>
                                    <th scope="col" className='col-1'>{t('forms:price')}</th>
                                    <th scope="col" className='col-1'>{t('forms:image')}</th>
                                    <th scope="col" className='col-1'>{t('forms:logo')}</th>
                                    <th scope="col" className='col-1'>{t('common:created_at')}</th>
                                    <th scope="col" className='col-1'>{t('common:expires_at')}</th>
                                    <th scope="col" className='col-1'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {shopItems.map((shopItem, index) => {
                                    let item = shopItem.shopItemDetail;
                                    return (
                                        <tr key={index} className="">
                                            <td>
                                                {item.company}
                                            </td>
                                            <td>
                                                {item.gems}
                                            </td>
                                            <td>
                                                <img src={item.bannerId} alt={"main banner"} className="img-fluid" />
                                            </td>
                                            <td>
                                                <img src={item.logoId} alt={"logo"} className="img-fluid" />
                                            </td>
                                            <td>
                                                {new Date(item.shopItemCreationDate).toLocaleDateString("it-IT")}
                                            </td>
                                            <td>
                                                {new Date(item.shopItemExpirationDate).toLocaleDateString("it-IT")}
                                            </td>
                                            <td>
                                                {/* icon button */}
                                                <button className="btn btn-sm btn-outline-primary"
                                                    onClick={() => {
                                                        setIsModalOpen(true);
                                                        setEditShopItem(shopItem);
                                                    }}
                                                ><i className="fa-duotone fa-pencil"></i></button>
                                                <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => {
                                                    if (window.confirm("Are you sure you want to delete this item?")) {
                                                        deleteShopItem(shopItem);
                                                    }
                                                }}><i className="fa-duotone fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    }
                    {
                        shopItems && shopItems.length === 0 &&
                        <div className="d-flex flex-column align-items-center">
                            <h1><i className="fa-duotone fa-shopping-bag"></i></h1>
                            <h3>{t('shop_manager:no_items')}</h3>
                        </div>}
                </div>
                {editShopItem != null && "aaa"}
                <EditShopItemModal isOpen={isModalOpen} isCreating={editShopItem == null} shopItem={editShopItem} setIsModalOpen={setIsModalOpen} />
            </div>
        </section>
    )
}

export function EditShopItemModal({ isOpen, isCreating, shopItem, setIsModalOpen }: { isOpen: boolean, isCreating: boolean, shopItem: ShopItem | null, setIsModalOpen: (isOpen: boolean) => void }) {
    const editShopItem = useShopManagerStore((state) => state.editShopItem);
    const createShopItem = useShopManagerStore((state) => state.createShopItem);
    const [image, setImage] = useState<File | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);
    const { t } = useTranslation();
    const getTranslationWithLanguage = useI18nStore(state => state.getTranslationWithLanguage);
    const quizLanguages = useI18nStore(state => state.quizLanguages);
    const [activeLanguage, setActiveLanguage] = useState(quizLanguages[0]);
    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        console.log(form);
        const formData = new FormData(form);
        const data: any = {};

        formData.forEach((value, key) => {
            if (key.includes('_')) {
                const [keyName, language] = key.split('_');
                if (!data[keyName]) {
                    data[keyName] = {};
                }
                data[keyName][language] = value;
            } else {
                data[key] = value;
            }
        });
        console.log(data);

        data.image = image;
        data.bannerImage = bannerImage;

        //format dates 
        data.shopItemStartDate = new Date(formData.get('shopItemStartDate')?.toString() ?? "").toISOString();
        data.shopItemExpirationDate = new Date(formData.get('shopItemExpirationDate')?.toString() ?? "").toISOString();
        if (isCreating) {
            toast.promise(createShopItem(data), {
                loading: t('common:saving'),
                success: <div className="d-flex flex-column">
                    <b>{t('shop_manager:shop_item')} {t('shop_manager:created')}!</b>
                </div>,
                error: t('errors:could_not_save')
            });
        } else {
            toast.promise(editShopItem(shopItem, data), {
                loading: t('common:saving'),
                success: <b>{t('shop_manager:shop_item')} {t('shop_manager:saved')}!</b>,
                error: t('errors:could_not_save')
            });
        }
        setIsModalOpen(false);
        setImage(null);
        setBannerImage(null);
        form.reset();
    }

    return (
        <Modal show={isOpen} onHide={() => setIsModalOpen(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCreating ? t('common:create', { capitalize: true }) : t('common:edit', { capitalize: true })} {t('shop_manager:shop_item')}
                    {!isCreating && <small className='text-muted d-flex'
                        style={{ fontSize: '0.8rem' }}
                    >
                        <span>ID:</span>&nbsp;
                        <div className='text-muted' style={{ cursor: 'copy' }} onClick={() => {
                            toast('Copied to clipboard!', { icon: '📋' });
                            navigator.clipboard.writeText(shopItem?.shopItemId ?? "");
                        }}>
                            {shopItem?.shopItemId}
                        </div>
                    </small>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('forms:company_name')}</Form.Label>
                        <Form.Control type="text" placeholder="Company name" name="company" defaultValue={shopItem?.shopItemDetail?.company ?? Config.defaultCompanyName} />
                    </Form.Group>
                    <Tabs activeKey={activeLanguage} onSelect={(k) => setActiveLanguage(k ?? quizLanguages[0])} className="">
                        {quizLanguages.map((language) => (
                            <Tab eventKey={language}

                                title={<><div>{language.toUpperCase()}
                                </div>
                                    <div>
                                        {getFlagEmoji(language)}
                                    </div></>}
                                className="p-3 border-bottom border-start border-end mb-3 rounded-bottom"
                                key={language}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('forms:title')}</Form.Label>
                                    <Form.Control type="text" placeholder={t('forms:title')} name={`title_${language}`} defaultValue={getTranslationWithLanguage(shopItem?.shopItemDetail.title, language)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('forms:description_brief')}</Form.Label>
                                    <Form.Control as="textarea" placeholder={t('forms:description_brief')} name={`descriptionBrief_${language}`} defaultValue={getTranslationWithLanguage(shopItem?.shopItemDetail.descriptionBrief, language)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('forms:description')}</Form.Label>
                                    <Form.Control as="textarea" placeholder={t('forms:description')} name={`description_${language}`} defaultValue={getTranslationWithLanguage(shopItem?.shopItemDetail.description, language)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('forms:terms_and_conditions')}</Form.Label>
                                    <Form.Control as="textarea" placeholder={t('forms:terms_and_conditions')} name={`termsAndConditions_${language}`} defaultValue={getTranslationWithLanguage(shopItem?.shopItemDetail.termsAndConditions, language)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Link</Form.Label>
                                    <Form.Control as="textarea" placeholder="Link" name={`howTo_${language}`} defaultValue={getTranslationWithLanguage(shopItem?.shopItemDetail.howTo, language)} />
                                </Form.Group>
                            </Tab>
                        ))}
                    </Tabs>
                    <Form.Group className="mb-3">
                        <Form.Label>Logo</Form.Label>
                        <div className="d-flex flex-column mb-3">
                            {!(shopItem?.shopItemDetail?.logoId || image) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                            {(shopItem?.shopItemDetail?.logoId || image) && <img src={image != null ? URL.createObjectURL(image) : (shopItem?.shopItemDetail?.logoId ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="Shop item logo" style={{ height: '16rem' }} />}
                            <Form.Control type="file" onChange={(event) => {
                                const target = event.target as HTMLInputElement;
                                if (target.files && target.files[0]) {
                                    setImage(target.files[0]);
                                }
                            }} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('forms:banner_image')}</Form.Label>
                        <div className="d-flex flex-column mb-3">
                            {!(shopItem?.shopItemDetail?.bannerId || bannerImage) && <h6>{t('forms:messages.no_image_selected')}</h6>}
                            {(shopItem?.shopItemDetail?.bannerId || bannerImage) && <img src={bannerImage != null ? URL.createObjectURL(bannerImage) : (shopItem?.shopItemDetail?.bannerId ?? "")} className="img-fluid object-fit-contain border rounded mb-3" alt="shop item banner" />}
                            <Form.Control type="file" onChange={(event) => {
                                const target = event.target as HTMLInputElement;
                                if (target.files && target.files[0]) {
                                    setBannerImage(target.files[0]);
                                }
                            }} />
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('forms:price')}</Form.Label>
                        <Form.Control type="number" min={0} placeholder="Coins required to unlock" name="gems" defaultValue={shopItem?.shopItemDetail?.gems ?? 500} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('forms:counter')}</Form.Label>
                        <Form.Control type="number" min={0} placeholder="How many items are available" name="counter" defaultValue={shopItem?.shopItemDetail?.counter ?? 1} />
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                        <div className="col-6">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" required name="shopItemStartDate" defaultValue={shopItem?.shopItemDetail.shopItemStartDate?.substring(0, 10) || new Date().toISOString().substring(0, 10)} />
                        </div>
                        <div className="col-6">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type="date" required name="shopItemExpirationDate" defaultValue={shopItem?.shopItemDetail.shopItemExpirationDate.substring(0, 10) || new Date().toDateString().substring(0, 10)} />
                        </div>
                    </Form.Group>
                    <div className="d-flex flex-row-reverse">
                        <Button type="submit" className="ms-2" variant="primary" data-bs-dismiss="modal">{t('common:save')}</Button>
                        <Button variant="secondary" data-bs-dismiss="modal">{t('common:close')}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
export default ShopManagerPage