import { create } from "./createStore";
import { AuthService } from "../services/authService";
import { useUserStore } from "./userStore";
import { useCompanyStore } from "./companyStore";
import { ApiError, ApiService, source } from "services/apiService";
import { AmplitudeService } from "services/amplitudeService";

export const AuthStates = {
    Authenticated: Symbol("authenticated"),
    Authenticating: Symbol("authenticating"),
    AuthenticationFailed: Symbol("authenticationFailed"),
    AuthenticationExpired: Symbol("authenticationExpired"),
    LoggingOut: Symbol("loggingOut"),
    Unauthorized: Symbol("unauthorized"),
    Unauthenticated: Symbol("unauthenticated"),
    Unconfirmed: Symbol("unconfirmed"),
    ResetPasswordRequired: Symbol("resetPasswordRequired"),
    Initial: Symbol("initial"),
}

interface AuthStoreState {
    authenthicationState: Symbol;
    authAccessToken: string | null;
    authRefreshToken: string | null;
    isAdmin: boolean;
    isSystem: boolean;
    isHR: boolean;
    isUser: boolean;
    init: () => Promise<void>;
    checkAuthState: () => Promise<Symbol | undefined>;
    refreshToken: () => Promise<string | Symbol | undefined>;
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    registerAccount: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
    confirmAccount: (email: string, code: string) => Promise<void>;
    resendEmail: (email: string) => Promise<void>;
    requestPasswordReset: (email: string) => Promise<void>;
    confirmPasswordReset: (email: string, code: string, newPassword: string) => Promise<void>;
    newPassword: (user: any, newPassword: string) => Promise<void>;
    currentAuthUser: any;
    deleteAccount: () => Promise<void>;
}


export const useAuthStore = create<AuthStoreState>()((set, get, __) => ({
    authenthicationState: AuthStates.Initial,
    authAccessToken: null,
    authRefreshToken: null,
    isAdmin: false,
    isSystem: false,
    isHR: false,
    isUser: false,
    currentAuthUser: null,
    init: async () => {
        console.log('[AuthService] INIT STARTED');
        await AuthService.init();
        await useAuthStore.getState().checkAuthState();
        console.log('[AuthService] INIT COMPLETE');
    },
    /// Gets the current auth state
    checkAuthState: async () => {
        try {
            // Check auth provider session
            console.log('[AuthService]: Checking auth state');
            console.log('[AuthService]: 1. Checking auth session');
            const session = await AuthService.checkAuthSession();
            if (!session) {
                set({ authenthicationState: AuthStates.Unauthenticated });
                console.log('[AuthService]: Unauthenticated');
                return;
            }
            set({ authAccessToken: session.getAccessToken().getJwtToken() });
            console.log('[AuthService]: 2. Access token', session.getAccessToken());
            // fetch user data
            let userProfile = await useUserStore.getState().initUser(session.getIdToken().payload.sub);
            if (userProfile) {
                await useCompanyStore.getState().initCompanyStore(userProfile.userProfileDetail.companyId);
                set({
                    authenthicationState: AuthStates.Authenticated,
                    isAdmin: userProfile.userProfileDetail.group === "Admin",
                    isSystem: userProfile.userProfileDetail.group === "System",
                    isHR: userProfile.userProfileDetail.group === "HR",
                    isUser: userProfile.userProfileDetail.group === "Users"
                });
                AmplitudeService.identify(userProfile);
                console.log('[AuthService]: 3. Authenticated');
                // Print role
                if (userProfile.userProfileDetail.group === "Admin") {
                    console.log('[AuthService]: Role: Admin', '👑');
                }
                if (userProfile.userProfileDetail.group === "System") {
                    console.log('[AuthService]: Role: System', '🌐');
                }
                if (userProfile.userProfileDetail.group === "HR") {
                    console.log('[AuthService]: Role: HR', '👩‍💼');
                }
                if (userProfile.userProfileDetail.group === "Users") {
                    console.log('[AuthService]: Role: User', '👤');
                }
                return;
            }
            set({ authenthicationState: AuthStates.Unauthenticated });
            console.log('[AuthService]: 3. Unauthenticated');
        } catch (e) {
            console.log('[AuthService]: Error', e);
            set({ authenthicationState: AuthStates.Unauthenticated });
            return AuthStates.Unauthenticated;
        }
    },
    /// refreshes the auth state
    refreshToken: async () => {
        try {
            // Check auth provider session
            console.log('[AuthService]: Refreshing auth state');
            console.log('[AuthService]: 1. Checking auth session');
            const session = await AuthService.checkAuthSession();
            set({ authAccessToken: session!.getAccessToken().getJwtToken() });
            console.log('[AuthService]: 2. Access token', session!.getAccessToken());
            return session!.getAccessToken().getJwtToken();
        } catch (e) {
            console.log('[AuthService]: Error', e);
            set({ authenthicationState: AuthStates.Unauthenticated });
            return AuthStates.Unauthenticated;
        }
    },
    /// Logs in the user and sets the auth state to Authenticated
    /// Operates on user store to save the user data
    login: async (email, password) => {
        set({ authenthicationState: AuthStates.Authenticating });
        let authUser;
        try {
            authUser = await AuthService.login(email, password);
        } catch (e) {
            if ((e as ApiError).message === "UserNotConfirmedException") {
                set({ authenthicationState: AuthStates.Unconfirmed, currentAuthUser: authUser });
                return;
            }
            /*             if ((e as ApiError).message === "NotAuthorizedException") {
                            set({ authenthicationState: AuthStates.Unauthorized });
                            return;
                        } */
        }
        if (authUser !== null) {
            // Check for challenges
            if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                set({ authenthicationState: AuthStates.ResetPasswordRequired, currentAuthUser: authUser });
                throw new Error("reset_password_required");
            }

            set({ authAccessToken: authUser.signInUserSession.accessToken.jwtToken, currentAuthUser: authUser });
            console.log('[AuthService] - LOGIN: Access token', authUser);

            let userProfile = await useUserStore.getState().initUser(authUser.attributes.sub);
            if (userProfile) {
                await useCompanyStore.getState().initCompanyStore(userProfile.userProfileDetail.companyId);
                AmplitudeService.identify(userProfile);

                set({
                    authenthicationState: AuthStates.Authenticated,
                    isAdmin: userProfile.userProfileDetail.group === "Admin",
                    isSystem: userProfile.userProfileDetail.group === "System",
                    isHR: userProfile.userProfileDetail.group === "HR",
                    isUser: userProfile.userProfileDetail.group === "Users"
                });


                console.log('[AuthService] - LOGIN: Login successful! Is Admin? ', userProfile.userProfileDetail.group === "Admin", ' Is System? ', userProfile.userProfileDetail.group === "System");
                return;
            }
        }
        set({ authenthicationState: AuthStates.AuthenticationFailed });
        throw new Error("login_failed");
    },
    logout: async () => {
        await set({ authenthicationState: AuthStates.LoggingOut });
        setTimeout(async () => {
            await AuthService.logout();
            source.cancel('User logged out');
            setTimeout(async () => {
                set({ authenthicationState: AuthStates.Unauthenticated, authAccessToken: null, currentAuthUser: null });
            }, 500);
        }, 200);
    },
    registerAccount: async (email, password, firstName, lastName) => {
        await AuthService.registerAccount(email, password, firstName, lastName);
    },
    confirmAccount: async (email, code) => {
        return await AuthService.confirmAccount(email, code);
    },
    resendEmail: async (email) => {
        return await AuthService.resendEmail(email);
    },
    requestPasswordReset: async (email) => {
        return await AuthService.requestPasswordReset(email);
    },
    confirmPasswordReset: async (email, code, newPassword) => {
        await AuthService.confirmPasswordReset(email, code, newPassword);
        set({ authenthicationState: AuthStates.Unauthenticated });
    },
    newPassword: async (user, newPassword) => {
        await AuthService.newPassword(user, newPassword);
        set({ authenthicationState: AuthStates.Unauthenticated });
    },
    deleteAccount: async () => {
        await ApiService.users.delete(useUserStore.getState().user!.userProfileId);
        await AuthService.logout();
    }
}));

